/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 *   Unless required by applicable law or agreed to in writing, software
 *   distributed under the License is distributed on an "AS IS" BASIS,
 *   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *   See the License for the specific language governing permissions and
 *   limitations under the License.
 */
export default {
    error: {
        msg_error: 'Se produjo un error',
        msg_description:
            'Contacta con el soporte tÃ©cnico'
    },
    Draft: 'Borrador',
    Revised: 'Revisado',
    Published: 'Presentado',
    Approved: 'Aprobado',
    Archived: 'Archivado',
    'published with errors': 'Publicado con errores',
    Correcting: 'SubsanaciÃ³n',
    Corrected: 'Subsanado',
    'Presentar TÃ­tulo': 'Presentar tÃ­tulo',
    'Archivar TÃ­tulo': 'Archivar tÃ­tulo',
    'Aprobar TÃ­tulo': 'Aprobar tÃ­tulo',
    'Master': 'Master de FormaciÃ³n Permanente',
    'University Expert': 'Experto Universitario',
    'Specialization Diploma': 'Diploma de EspecializaciÃ³n',
    'University Certificate': 'Certificado de FormaciÃ³n ContinÃºa',
    Center: 'Centro',
    Department: 'Departamento',
    'Research Institute': 'Instituto de InvestigaciÃ³n',
    'Art and Humanities': 'Artes y Humanidades',
    Science: 'Ciencia',
    'Health Science': 'Ciencias de la salud',
    'Social and Legal Science': 'Ciencias Sociales y jurÃ­dicas',
    'Engineering and Architecture': 'IngenierÃ­a y Arquitectura',
    'Business Management': 'DireccciÃ³n y GestiÃ³n empresarial',
    'Social and Legal': 'JurÃ­dico y Social',
    Humanities: 'Humanidades',
    'Education Science': 'Ciencias de la educaciÃ³n',
    Psychology: 'PsicologÃ­a',
    'Environmental Health and Security': 'Seguridad, Salud y Medioambiente',
    Health: 'Salud',
    'Science and Technology': 'Ciencias y tecnologÃ­a',
    'Physical Activity and Sport': 'Actividad fÃ­sica y Deporte',
    Spanish: 'Castellano',
    ValenciÃ : 'Valenciano',
    English: 'InglÃ©s',
    'Online master': 'No presencial (on-line)',
    'Face To Face': 'Presencial',
    'Semi Face To Face': 'Semi-presencial',
    'TEACHER_OTHER_UNIV': 'Profesores de otras universidades',
    'TEACHER_EXTERNAL': 'Profesores Externos',
    'CENTER': 'Centros',
    'TEACHER_UV': 'Profesores UV',
    'COMPANY': 'Empresas',
    'DEPARTMENT': 'Departamentos',
    'PROFESSIONAL_CATEGORY': 'CategorÃ­a Profesional',
    'Microcredential': 'Microcredencial',
    "TÃ­tulos": "TÃ­tulos",
    "DEFAULT": "TÃ­tulo propio",
    "CONTINOUS_LEARNING": "FormaciÃ³n contÃ­nua"

};
