
import Vue from 'vue';
// @ts-ignore
import { authentication } from '@/services';
import vuejsCountDown from './common/CountDown.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
    components: {
        vuejsCountDown,
    },
    data() {
        return {
            appTitle: this.$i18n.t('TÃ­tulos Propios UV'),
            drawer: false,
            group: null,
            items: [
                { title: this.$i18n.t('BackOffice') },
                { title: this.$i18n.t('Menu Inicio') },
                { title: this.$i18n.t('Idioma') },
                { title: this.$i18n.t('Cerrar sesiÃ³n') },
                { title: this.$i18n.t('Manual de Usuario') },
            ],
            languages: [
                { title: 'Castellano', lang: 'es' },
                { title: 'ValenciÃ ', lang: 'va' },
                { title: 'English', lang: 'en' },
            ],
        };
    },
    computed: {
        ...mapGetters({
            config: 'config/get',
            currentTenant: 'tenant/getCurrentTenant',
        }),
        colorBar() {
            return process.env.NODE_ENV == 'production' ? 'primary lighten-5' : 'red';
        },
        deadLineEndDate() {
            const d = new Date(this.config.deadline_end_date_time);
            d.setDate(d.getDate() + 1);
            return d.toDateString();
        },
        deadline_indate() {
            const now = new Date().toISOString().split('T')[0];

            return (
                now >= this.config.deadline_start_date_time &&
                now <= this.config.deadline_end_date_time
            );
        },
    },
    methods: {
        toLogin() {
            this.$router.push('/login');
        },
        toLogout() {
            // @ts-ignore
            this.logout(); // mixin
            this.$router.push('/login');
        },
        setLang(lang) {
            this.$i18n.locale = lang;
        },
    },
});
