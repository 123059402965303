
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
// @ts-ignore
import * as _ from 'lodash';
import * as api from '@/api/index';
import NumericField from '@/components/common/NumericField.vue';
import { form } from '@/components/mixins';
import SaveCancel from '../common/SaveCancel.vue';
import * as helpers from '@/helpers/index';
import FeeList from '../common/FeeList.vue';
import SimpleCurrencyField from '@/components/common/SimpleCurrencyField.vue';

export default Vue.extend({
    name: 'Budget',
    components: {
        NumericField,
        SaveCancel,
        FeeList,
        SimpleCurrencyField,
    },
    mixins: [form],
    props: {
        editStep: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            headers_childs: [
                {
                    text: this.$i18n.t('TÃ­tulo'),
                    align: 'left',
                    sortable: true,
                    value: 'title',
                },
                {
                    text: this.$i18n.t('Total Ingresos'),
                    align: 'right',
                    sortable: true,
                    value: 'budget',
                },
            ],
            api: api,
            valid: false,
            errors: {
                enrollment_price: [],
                min_students: [],
                budget: {
                    income_sponsorship: [],
                },
            },
            rules: {
                enrollment_price: [],
                min_students: [],
                budget: {
                    income_sponsorship: [],
                    expense_other_amount: [
                        (v: number) => {
                            if (!this.localState.budget) {
                                return true;
                            }

                            const percentMax =
                                (this.localState.budget.total_income *
                                    this.constants.OTHER_AMOUNT_MAX_PERCENT) /
                                100;

                            const current_value =
                                this.$refs.expense_other_amount.formattedValue;
                            console.log(
                                `Av ore con v = ${Number(
                                    current_value
                                )} y percentMax = ${percentMax}`
                            );

                            if (Number(current_value) > Number(percentMax)) {
                                return (
                                    (this.$i18n.t(
                                        `MÃ¡ximo ${this.constants.OTHER_AMOUNT_MAX_PERCENT}% del total de ingresos`
                                    ) as string) + `(${percentMax}â¬)`
                                );
                            } else {
                                return true;
                            }
                        },
                    ],
                    sumSalary: [
                        (v: number) => {
                            if (!this.$refs.salary) {
                                return true;
                            }

                            let newSumDirectorSalaries = 0;
                            // @ts-ignore
                            for (let s of this.$refs.salary) {
                                newSumDirectorSalaries +=
                                    helpers.unformatString(s.formattedValue);
                            }

                            if (
                                Number(newSumDirectorSalaries) >
                                Number(this.maxDirectorsSalary)
                            ) {
                                return this.$i18n.t(
                                    'La suma de salarios excede el mÃ¡ximo permitido'
                                );
                            } else {
                                return true;
                            }
                        },
                    ],
                },
            },
            localState: {
                min_students: null,
                enrollment_price: null,
                budget: {
                    income_sponsorship: null,
                },
            },
            pendingChanges: false,
        };
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            globalState: 'master/getData',
            constants: 'master/getConstants',
        }),
        sumDirectorSalaries(): number {
            const sum = this.globalState.directors.reduce((acumm, director) => {
                return acumm + (director.MasterTeacherDirector.salary || 0);
            }, 0);

            return sum;
        },
        maxDirectorsSalary() {
            // use the total_income calculated with values in form
            // to validate previously to the update operation
            return (
                (this.freshTotalIncome *
                    this.constants.MAX_DIRECTORS_SALARY_PERCENT) /
                100
            );
        },
        onlyMasterTotalIncome(): number {
            return (
                Number(this.localState.budget.income_sponsorship || 0) +
                Number(this.localState.budget.total_fee)
            );
        },
        freshTotalIncome() {
            return (
                (this.onlyMasterTotalIncome as number) +
                Number(this.localState.budget.total_childs_income || 0)
            );
        },
        expenseAdministrationByIncomeSponsorShip() {
            if (!this.globalState.budget) {
                return 0;
            }

            if (this.globalState.budget.income_sponsorship > 0) {
                return this.constants
                    .EXPENSE_ADMINISTRATION_PERCENT_WITH_SPONSORSHIP;
            } else {
                return this.constants.EXPENSE_ADMINISTRATION_PERCENT;
            }
        },
        expenseAdminstrationApplied() {
            return this.globalState.expense_administration_percent > 0
                ? this.globalState.expense_administration_percent
                : this.expenseAdministrationByIncomeSponsorShip;
        },
        isChildMaster(): boolean {
            return this.globalState.master_parent_id != null;
        },
    },
    created() {
        this.parseGlobalState();
    },
    mounted() {
        Vue.nextTick(() => this.cancelChanges());
    },
    methods: {
        ...mapActions('master', ['update', 'clearError']),
        forceNumber(evt: any) {
            if (!helpers.isKeyNumber(evt)) {
                evt.preventDefault();
            }
        },
        parseGlobalState() {
            // @ts-ignore
            _.merge(this.localState, _.cloneDeep(this.globalState));

            if (!this.localState.budget) {
                return;
            }

            Vue.set(this.localState, 'budget', this.globalState.budget);

            Vue.set(
                this.localState.budget,
                'result',
                this.localState.budget.total_income -
                    this.localState.budget.total_expense
            );
        },
        parseLocalState() {
            return this.localState;
        },
        clearLocalState() {
            this.localState = {
                enrollment_price: null,
                min_students: null,
                budget: {
                    income_sponsorship: null,
                },
            };

            this.clearError();

            // @ts-ignore
            this.$refs.form.resetValidation();
        },
        setModified() {
            this.pendingChanges = true;
        },
        clearModified() {
            this.pendingChanges = false;
        },
        cancelChanges() {
            this.parseGlobalState();
            this.clearModified();
        },
        async save() {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                this.parseLocalState();
                // @ts-ignore
                await this.update(this.localState);
                this.clearModified();
                this.$emit('updated', false);
            }
        },
    },
    watch: {
        globalState: {
            handler(newValue, oldValue) {
                this.parseGlobalState();
            },
            deep: true,
        },
    },
});
