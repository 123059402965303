
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import {IAcademicYear} from '../interfaces/master/interfaces/models/IAcademicYear';
import {MASTER_KIND} from '../helpers';

export default Vue.extend({
    name: 'MasterNew',
    props: {
        masterKind: {
            type: Number,
            default: MASTER_KIND.DEFAULT,
        },
    },
    data() {
        return {
            MASTER_KIND: MASTER_KIND,
            valid: false,
            errors: {
                academic_year: [],
                academic_years_duration: [],
                title: [],
            },
            rules: {
                academic_year: [
                    (v: IAcademicYear) =>
                        !!v || this.$i18n.t('El aÃ±o acadÃ©mico es requerido'),
                ],
                academic_years_duration: [
                    (v: IAcademicYear) =>
                        !!v || this.$i18n.t('La duraciÃ³n es requerida'),
                ],
                title: [
                    (v: string) =>
                        !!v || this.$i18n.t('El tÃ­tulo es requerido'),
                ],
            },
            master: {
                academic_year: null,
                academic_years_duration: 1,
                title: null,
                teacher_responsible_id: null,
                master_kind_id: null,
            },
        };
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            academicYears: 'master/getAcademicYears',
            getOpenedAcademicYearsOwnDegrees:
                'master/getOpenedAcademicYearsOwnDegrees',
            getOpenedAcademicYearsContinuousLearningDegrees:
              'master/getOpenedAcademicYearsContinuousLearningDegrees',
            academicYearsDuration: 'master/getAcademicYearsDuration',
        }),
        getAcademicYearsForNewOwnDegrees() {
            return this.getOpenedAcademicYearsOwnDegrees;
        },
        getAcademicYearsForNewContinuousLearningDegrees() {
          return this.getOpenedAcademicYearsContinuousLearningDegrees;
        },
    },
    mounted() {
        this.clearLocalState();
    },
    methods: {
        ...mapActions('master', ['create', 'clearError']),
        clearLocalState() {
            this.master = {
                academic_year: this.academicYears[0],
                academic_years_duration: 1,
                title: null,
                teacher_responsible_id: null,
            };

            this.clearError();

            // @ts-ignore
            this.$refs.form.resetValidation();
        },
        exit() {
            this.clearLocalState();
            this.$emit('input', false);
        },
        async save() {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                // @ts-ignore
                if (this.imRoleTeacher) {
                    this.master.teacher_responsible_id =
                        this.currentUser.teacher.id;
                }
                this.master.master_kind_id = this.masterKind;

                // @ts-ignore
                await this.create(this.master);
                this.$emit('created', this.master);
                this.clearLocalState();
            }
        },
    },
});
