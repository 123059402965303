/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */

// @ts-ignore
import * as api from '@/api/index';

// @ts-ignore
import * as mutation_types from '@/vuex/mutation_types';
import {ActionTree} from 'vuex';
import {RootState} from '../../types';
// @ts-ignore
import {IMaster} from '../../../interfaces/master/interfaces/models/IMaster';
import {IEnumerates, MasterState} from './types';
import {AxiosResponse} from 'axios';

// @ts-ignore
import * as _ from 'lodash';
import {ITeacher} from '../../../interfaces/teacher/interfaces/models/ITeacher';
import i18n from '../../../lang';
import {ISubject} from '../../../interfaces/subject/interfaces/models/ISubject';

export const actions: ActionTree<MasterState, RootState> = {
    get: async function ({ commit }, { masterId }): Promise<any> {
        try {
            commit(mutation_types.CLEAR_DATA);

            const result: AxiosResponse = await api.master.get(masterId);

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    create: async function ({ commit }, master): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.create(master);

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    clone: async function (
        { commit },
        { masterId, academicYearId }
    ): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.clone(
                masterId,
                academicYearId
            );

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    update: async function ({ commit }, master): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.update(master);

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    delete: async function ({ commit }, { master }): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.delete(master);

            // commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    clearError: async function ({ commit }): Promise<any> {
        commit(mutation_types.CLEAR_ERROR, undefined);
    },
    getEnumerates: async function ({ commit }): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.getEnumerates();

            const constants = {
                MAX_SUBJECT_PRICE: 125,
                MAX_SUBJECTS_HOURS: 100,
                OTHER_AMOUNT_MAX_PERCENT: 5,
                MAX_DIRECTORS_SALARY_PERCENT: 10,
                UV_TAX_PERCENT: 10,
                EXPENSE_ADMINISTRATION_PERCENT: 15,
                EXPENSE_ADMINISTRATION_PERCENT_WITH_SPONSORSHIP: 15,
                SCHOLARSHIP_PERCENT: 2,
                SUBJECT_MIN_ECTS: 3,
                SUBJECT_TFM_MIN_ECTS: 6,
            };

            result.data.constants = constants;
            result.data.academic_years_duration = [
                {
                    duration: 1,
                    description: i18n.t('Un curso acadÃ©mico'),
                },
                {
                    duration: 2,
                    description: i18n.t('Dos cursos acadÃ©micos'),
                },
                {
                    duration: 3,
                    description: i18n.t('Tres cursos acadÃ©micos'),
                },
            ];

            commit(mutation_types.LOAD_ENUMERATES_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    setEnumerates: async function ({ commit }, enumerates ): Promise<any> {
        try {
            enumerates['constants'] = {
                MAX_SUBJECT_PRICE: 125,
                MAX_SUBJECTS_HOURS: 100,
                OTHER_AMOUNT_MAX_PERCENT: 5,
                MAX_DIRECTORS_SALARY_PERCENT: 10,
                UV_TAX_PERCENT: 10,
                EXPENSE_ADMINISTRATION_PERCENT: 15,
                EXPENSE_ADMINISTRATION_PERCENT_WITH_SPONSORSHIP: 15,
                SCHOLARSHIP_PERCENT: 2,
                SUBJECT_MIN_ECTS: 3,
                SUBJECT_TFM_MIN_ECTS: 6,
            };

            commit(mutation_types.LOAD_ENUMERATES_DATA, enumerates as IEnumerates);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    createSubject: async function (
        { commit },
        { subject, master }
    ): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.createSubject(
                subject,
                master
            );

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    updateSubject: async function (
        { commit },
        { subject, master }
    ): Promise<any> {
        try {
            const localSubject = _.cloneDeep(subject);
            const localMaster = _.cloneDeep(master);

            const result: AxiosResponse = await api.master.updateSubject(
                localSubject,
                localMaster
            );
            /* eslint-enable */
            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    updateSubjectsOrder: async function (
        { commit },
        { subjects, masterId }
    ): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.updateSubjectsOrder(
                subjects,
                masterId
            );
            /* eslint-enable */
            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    linkSubjects: async function (
        { commit },
        { subjects, master }
    ): Promise<any> {
        try {
            const localSubjects = subjects.map((s: ISubject) => s.id);
            const localMaster = _.cloneDeep(master);

            const result: AxiosResponse = await api.master.linkSubjects(
                localSubjects,
                localMaster
            );

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    unlinkSubject: async function (
        { commit },
        { subject, master }
    ): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.unlinkSubject(
                subject,
                master
            );

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    createFee: async function ({ commit }, { fee, master }): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.createFee(
                fee,
                master
            );

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    updateFee: async function ({ commit }, { fee, master }): Promise<any> {
        try {
            const localFee = _.cloneDeep(fee);
            const localMaster = _.cloneDeep(master);

            const result: AxiosResponse = await api.master.updateFee(
                localFee,
                localMaster
            );

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    deleteFee: async function ({ commit }, { fee, master }): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.deleteFee(
                fee,
                master
            );

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    createTeacherSubject: async function (
        { commit },
        { masterId, teacherSubject }
    ): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.createTeacherSubject(
                masterId,
                teacherSubject
            );

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    deleteTeacherSubject: async function (
        { commit },
        { masterId, teacherSubject }
    ): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.deleteTeacherSubject(
                masterId,
                teacherSubject
            );

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    updateTeacherSubject: async function (
        { commit },
        { masterId, teacherSubject }
    ): Promise<any> {
        try {
            const localTeacherSubject = _.cloneDeep(teacherSubject);

            const result: AxiosResponse = await api.master.updateTeacherSubject(
                masterId,
                localTeacherSubject
            );

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    updateDirectorsOrder: async function (
        { commit },
        { directors, masterId }
    ): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.updateDirectorsOrder(
                directors,
                masterId
            );
            /* eslint-enable */
            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    deleteSubject: async function (
        { commit },
        { subject, master }
    ): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.deleteSubject(
                subject,
                master
            );

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    uploadFile: async function (
        { commit },
        { masterId, masterFileType, file }
    ): Promise<any> {
        try {
            if (!file) {
                throw new Error(i18n.t('Fichero vacÃ­o') as string);
            }

            const result = await api.master.uploadFile(
                masterId,
                masterFileType,
                file
            );

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    deleteFile: async function (
        { commit },
        { masterId, masterFileType }
    ): Promise<any> {
        try {
            if (!masterId) {
                throw new Error(i18n.t('master unknown') as string);
            }

            const result = await api.master.deleteFile(
                masterId,
                masterFileType
            );

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    createTeacher: async function ({ commit }, teacher): Promise<any> {
        try {
            const result: AxiosResponse = await api.teacher.create(teacher);

            commit(mutation_types.CREATE_TEACHER, result.data as ITeacher);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    updateTeacher: async function ({ commit }, teacher): Promise<any> {
        try {
            const localTeacher = _.cloneDeep(teacher);

            const result: AxiosResponse = await api.teacher.update(teacher);

            commit(mutation_types.UPDATE_TEACHER, result.data as ITeacher);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    clearNewTeacher: async function ({ commit }): Promise<any> {
        commit(mutation_types.CLEAR_NEW_TEACHER, undefined);
    },
    clearNewFee: async function ({ commit }): Promise<any> {
        commit(mutation_types.CLEAR_NEW_FEE, undefined);
    },
    exportToSigue: async function ({ commit }, masterId: number): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.exportToSigue(
                masterId
            );
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    publish: async function ({ commit }, masterId: number): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.publish(masterId);

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    review: async function ({ commit }, masterId: number): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.review(masterId);

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    correct: async function ({ commit }, masterId: number): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.correct(masterId);

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    archive: async function ({ commit }, masterId: number): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.archive(masterId);

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    draft: async function ({ commit }, masterId: number): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.draft(masterId);

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    approve: async function ({ commit }, masterId: number): Promise<any> {
        try {
            const result: AxiosResponse = await api.master.approve(masterId);

            commit(mutation_types.LOAD_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
};
