
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import {IAcademicYear} from '../../interfaces/master/interfaces/models/IAcademicYear';
import {ITeacher} from '../../interfaces/teacher/interfaces/models/ITeacher';
import SelectTeacher from '@/components/common/SelectTeacher.vue';
import {form} from '@/components/mixins';
import {COLORS, MASTER_KIND} from '../../helpers';
import DirectorMaster from './DirectorMaster.vue';
import * as api from '@/api';
import TeacherListItem from '../common/TeacherListItem.vue';
import SaveCancel from '../common/SaveCancel.vue';
import Draggable from 'vuedraggable';
import ConfirmDialog from '@/components/common/ConfirmDialog.vue';

export default Vue.extend({
    name: 'Management',
    components: {
        TeacherListItem,
        SelectTeacher,
        DirectorMaster,
        ConfirmDialog,
        SaveCancel,
        Draggable,
    },
    mixins: [form],
    props: {
        editStep: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            errors: {
                academic_year: [],
                academic_year_duration: [],
                title: [],
                title_val: [],
                modular_master: [],
            },
            rules: {
                academic_year: [
                    (v: IAcademicYear) =>
                        !!v || this.$i18n.t('El aÃ±o acadÃ©mico es requerido'),
                ],
                academic_years_duration: [
                    (v: IAcademicYear) =>
                        !!v || this.$i18n.t('La duraciÃ³n es requerida'),
                ],
                title: [
                    (v: string) =>
                        !!v || this.$i18n.t('El tÃ­tulo es requerido'),
                ],
                title_val: [],
                teacher_responsible: [
                    (v: ITeacher) =>
                        !!v ||
                        this.$i18n.t(
                            'El director responsable de la uv es requerido'
                        ),
                ],
                directors_uv: [
                    (v: ITeacher) =>
                        !!v ||
                        v.length === 0 ||
                        this.$i18n.t(
                            'El director responsable de la uv es requerido'
                        ),
                ],
                directors_external: [],
                modular_master: [],
            },
            localState: {
                academic_year: null,
                academic_year_duration: null,
                title: null,
                title_val: null,
                teacher_responsible: null,
                directors: [],
                directors_uv: [],
                directors_ext: [],
                modular: false,
                modular_master: null,
                modular_master_id: null,
                master_kind_id: null,
                master_parent_id: null,
            },
            openDirectorUI: false,
            removeParentUI: false,
            newDirectors: [],
            newDirectorType: 'uv',
            modularMasters: [],
            MASTER_KIND: MASTER_KIND,
        };
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            globalState: 'master/getData',
            academicYears: 'master/getAcademicYears',
            academicYearsDuration: 'master/getAcademicYearsDuration',
            masterTypes: 'master/getMasterTypesAsObject',
        }),
        directors_uv: {
            get() {
                return this.localState.directors_uv;
            },
            set(value) {
                this.localState.directors_uv = value;
            },
        },
        directors_ext: {
            get() {
                return this.localState.directors_ext;
            },
            set(value) {
                this.localState.directors_ext = value;
            },
        },
        directorsDisabled() {
            let directors: ITeacher[] = [
                ...this.directors_uv,
                ...this.directors_ext,
            ];
            return directors.some((director) => director.active == false);
        },
    },
    methods: {
        ...mapActions('master', [
            'update',
            'clearError',
            'updateDirectorsOrder',
        ]),
        async getModularMasters() {
            if (this.globalState.academic_year == null) {
                return;
            }

            try {
                const filter = { modular: true };

                const response = await api.master.getAll({
                    filter: JSON.stringify(filter),
                    academicYearId: this.globalState.academic_year_id,
                });

                Vue.set(this, 'modularMasters', response.data.data);
            } catch (err) {
                throw err;
            }
        },
        clearLocalState() {
            this.localState = {
                academic_year: null,
                academic_years_duration: null,
                title: null,
                title_val: null,
                teacher_responsible: null,
                directors: [],
                directors_uv: [],
                directors_ext: [],
                modular: false,
                modular_master: null,
                modular_master_id: null,
            };

            this.clearError();

            // @ts-ignore
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        },
        parseGlobalStateAfter() {
            Vue.set(
                this.localState,
                'teacher_responsible',
                this.globalState.teacher_responsible
            );
        },
        parseLocalStateAfter() {
            this.localState.directors =
                this.localState.directors_uv.concat(
                    this.localState.directors_ext
                );

            this.localState.academic_year_id = this.localState.academic_year.id;
            this.localState.modular_master_id =
                this.localState.modular_master?.id;
        },
        handleResponsible(teacher: ITeacher) {
            if (teacher == null) {
                return;
            }

            const directorsIds = this.directors_uv.map((el: ITeacher) => {
                return el.id;
            });

            // add if newValue is not in directors_uv
            if (
                this.directors_uv.length == 0 || // directors_uv is empty
                //directors_uv is not empty but not contains newValue
                (directorsIds &&
                    directorsIds.length > 0 &&
                    !directorsIds.includes(teacher.id))
            ) {
                this.directors_uv.push(teacher);
            }
        },
        getInitials(director: ITeacher) {
            return (
                director.name.substring(0, 1) + director.surname.substring(0, 1)
            );
        },
        getColor(): string {
            const i = Math.ceil(Math.random() * COLORS.length);
            return COLORS[i];
        },
        newUVDirector() {
            this.openDirectorUI = true;
            // call action
            this.newDirectors = this.directors_uv;
            this.newDirectorType = 'uv';
        },
        newExtDirector() {
            this.openDirectorUI = true;
            // call action
            this.newDirectors = this.directors_ext;
            this.newDirectorType = 'ext';
        },
        addDirector(director: ITeacher, type: string) {
            if (type === 'uv') {
                this.directors_uv.push(director);
            }

            if (type === 'ext') {
                this.directors_ext.push(director);
            }

            this.setModified();
            this.openDirectorUI = false;
            this.save();
        },
        deleteUvDirector(director: ITeacher) {
            this.directors_uv = this.directors_uv.filter((d) => {
                return d.id != director.id;
            });

            this.save();
        },
        deleteExtDirector(director: ITeacher) {
            this.directors_ext = this.directors_ext.filter((d) => {
                return d.id != director.id;
            });

            this.save();
        },
        onUVOrderChanged() {
            // save subjects order
            const listDirectors = this.$refs['directors-uv-list']['list'];
            console.log(listDirectors);
            const orderedDirectors = listDirectors
                .map((director: ITeacher, index: number) => {
                    if (director != null) {
                        return {
                            id: director.id,
                            order: index + 1,
                        };
                    }
                })
                .sort((a, b) => a.order - b.order);

            this.updateDirectorOrder(orderedDirectors, this.localState.id);
        },
        async updateDirectorOrder(directors: ITeacher[], masterId: number) {
            // @ts-ignore
            await this.updateDirectorsOrder({
                directors: directors,
                masterId: masterId,
            });
        },
        onUVMove() {
            return true;
        },
        convert() {
            this.localState.master_kind_id = MASTER_KIND.CONTINUOUS_LEARNING;

            this.save();
        },
        unlink() {
            this.localState.master_parent_id = null;

            this.save();
            this.removeParentUI = false;
        },
        showRemoveParentUI() {
          this.removeParentUI = true;
        },
        cancelRemoveParent() {
          this.removeParentUI = false;
        }
    },
    watch: {
        globalState(newValue, oldValue) {
            this.clearLocalState();
            this.parseGlobalState();
            if (newValue != null) {
                this.getModularMasters();
            }
        },
        // mantain in sync teacher_responsible and directors_uv
        // if new teacher_responsible, then remove old from directors_uv and add the new one
        // sync new responsible is made through handleNewResponsible method in change event of responsible v-autocomplete
        'localState.teacher_responsible'(newValue, oldValue) {
            if (newValue == null) {
                // removes the old one
                this.directors_uv = this.directors_uv.filter(
                    (el) => el.id != oldValue.id
                );
            }
        },
    },
});
