
import Vue from 'vue';

export default Vue.extend({
    name: 'TeachingReview',
    props: {
        teacherType: {
            type: String
        },
        editStep: {
            type: Number,
            default: null
        }
    },
    computed: {
        percentColor() {
            if (this.teacherType === 'uv') {
                if (this.percentHoursOverTotal < 25) {
                    return 'red';
                } else {
                    return this.colorField;
                }
            } else {
                if (this.percentHoursOverTotal > 75) {
                    return 'red';
                } else {
                    return this.colorField;
                }
            }
        },
        colorField() {
            if (this.teacherType === 'uv') {
                return 'blue-grey';
            } else {
                return 'brown darken-1';
            }
        },
        typeText() {
            if (this.teacherType === 'uv') {
                return 'uv';
            } else {
                return 'externo';
            }
        },
        formError() {
            return this.$store.getters['master/getError'];
        },
        teachers() {
            const tuv = this.$store.getters['master/getTeachersReview'];
            return tuv.filter(
                (teacher: any) => teacher.teacher_type_code === this.teacherType
            );
        },
        totalPrice() {
            const total = this.teachers.reduce((ant, teacher) => {
                return ant + teacher.total_price;
            }, 0);

            return total.toLocaleString();
        },
        totalTeachingHours() {
            const total = this.teachers.reduce((ant, teacher) => {
                return (
                    ant +
                    teacher.tutorial_teaching_hours +
                    teacher.practical_teaching_hours +
                    teacher.theoretical_teaching_hours
                );
            }, 0);

            return total.toLocaleString();
        },
        totalMasterTeachingHours() {
            const tr = this.$store.getters['master/getTeachersReview'];

            const total = tr.reduce((ant, teacher) => {
                return (
                    ant +
                    teacher.tutorial_teaching_hours +
                    teacher.practical_teaching_hours +
                    teacher.theoretical_teaching_hours
                );
            }, 0);

            return total.toLocaleString();
        },
        percentHoursOverTotal() {
            return (
                (this.totalTeachingHours / this.totalMasterTeachingHours) *
                100
            ).toFixed(2);
        }
    },
    data() {
        return {
            trIndex: 2,
            search: '',
            headers: [
                {
                    text: this.$i18n.t('Profesor'),
                    align: 'left',
                    sortable: true,
                    value: 'name',
                    width: '400px'
                },
                {
                    text: this.$i18n.t('Docencia teÃ³rica'),
                    value: 'theoretical_teaching_hours',
                    align: 'center',
                    sortable: true
                },
                {
                    text: this.$i18n.t('Docencia prÃ¡ctica'),
                    value: 'practical_teaching_hours',
                    align: 'center',
                    sortable: true
                },
                {
                    text: this.$i18n.t('TutorÃ­as obligatorias'),
                    value: 'tutorial_teaching_hours',
                    align: 'center',
                    sortable: true
                },
                {
                    text: this.$i18n.t('Coste Total'),
                    value: 'total_price',
                    align: 'center',
                    sortable: true
                }
            ]
        };
    },
    methods: {
        tableHeaderIndex(i: number) {
            return Math.random() + i;
        }
    }
});
