/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 *   Unless required by applicable law or agreed to in writing, software
 *   distributed under the License is distributed on an "AS IS" BASIS,
 *   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *   See the License for the specific language governing permissions and
 *   limitations under the License.
 */
export default {
    error: {
        msg_error: "S'ha produÃ¯t un error",
        msg_description: 'Contacta amb el suport o obri una incidÃ¨ncia'
    },
    'MenÃº Inicio': 'MenÃº Inici',
    'Iniciar sesiÃ³n': 'Iniciar sessiÃ³',
    'Cerrar sesiÃ³n': 'Tancar sessiÃ³',
    'Manual de usuario': 'Manual d\'usuari',
    'TÃ­tulos Propios UV': 'TÃ­tols Propis UV',
    Menu: 'MenÃº',
    Borrar: 'Esborrar',
    Confirmar: 'Confirmar',
    'EstÃ¡s seguro/a de eliminar la referencia al tÃ­tulo vinculado(padre)?': "EstÃ s segur/a d'eliminar la referÃ¨ncia al tÃ­tol vinculat(pare)?",
    'EstÃ¡s seguro/a?': 'EstÃ s segur/a?',
    Cancel: 'CancelÂ·lar',
    Aceptar: 'Acceptar',
    'El item serÃ¡ borrado permanentemente. Quieres continuar':
        "L'Ã­tem serÃ  esborrat permanentment. Vols continuar",
    'Nueva tasa': 'Nova tasa',
    'Modificar tasa': 'Modificar tasa',
    'Ha ocurrido un error en el servidor':
        'Ha ocorregut una errada al servidor',
    'Grupo o pÃºblico al que va dirigida la tasa':
        'Grup o pÃºblic al qual va adreÃ§ada la tasa',
    'Importe de la matrÃ­cula': 'Import de la matrÃ­cula',
    'MÃ­nimo de estudiantes': "MÃ­nim d'estudiantat",
    Guardar: 'Guardar',
    'No puede ser mayor que el nÃºmero mÃ¡ximo de estudiantes del tÃ­tulo':
        "No pot ser major que el nombre mÃ xim d'estudiants del tÃ­tol",
    'Tasas del tÃ­tulo': 'Tases del tÃ­tol',
    'Guarda los cambios para poder modificar las tasas del tÃ­tulo':
        'Guarda els canvis per poder modificar les tases del tÃ­tol',
    'Eliminar tasa': 'Eliminar',
    'Se eliminarÃ¡ la tasa completa, lo que afectarÃ¡ al presupuesto.':
        "S'eliminarÃ  la tasa completa, la qual cosa afectarÃ  al pressupost",
    PÃºblico: 'PÃºblic',
    'MÃ­n. Estudiantes': 'MÃ­n. estudiants',
    'MatrÃ­cula ': 'MatrÃ­cula ',
    'Total ': 'Total ',
    'Elimina el fichero': 'Elimina el fitxer',
    'Descarga el fichero': 'Descarrega el fitxer',
    'Haz clik para adjuntar un nuevo fichero':
        'Fes click per adjuntar un nou fitxer',
    Salir: 'Eixir',
    'Cancelar cambios': 'CancelÂ·lar canvis',
    'Escribe para buscar una organizaciÃ³n':
        'Escriu per cercar una organitzaciÃ³',
    'El dato es requerido': 'Dada requerida',
    'Se ha producido un error consultando el servidor':
        "S'ha produÃ¯t una errada consultant el servidor",
    nuevo: 'nou',
    'Escribe para buscar por nombre, apellidos o NIF':
        'Per cercar, escriu nom, cognoms o NIF',
    'Eliminar ': 'Eliminar',
    'Crea tÃ­tulos propios': 'Crea tÃ­tols propis',
    'Crear TÃ­tulo': 'Crear TÃ­tol',
    'Crear TÃ­tulo Vinculado': 'Crear TÃ­tol Vinculat',
    'Gestiona TÃ­tulos Propios': 'Gestiona TÃ­tols Propis',
    'Revisar / Modificar tÃ­tulos propios presentados': 'Revisar / Modificar TÃ­tols Propis presentats',
    'Inicia sesiÃ³n': 'Inicia sessiÃ³',
    'Error al iniciar sesiÃ³n': 'Errada en iniciar sessiÃ³',
    'Nombre de usuario UV o Correo electrÃ³nico UV':
        "Nom d'usuari UV o correu electrÃ²nic UV",
    'Puedes introducir tu nombre de usuario uv o el correo electrÃ³nico':
        "Pots introduir el teu nom d'usuari UV o el correu electrÃ²nic",
    ContraseÃ±a: 'Contrasenya',
    'ContraseÃ±a requerida': 'Contrasenya requerida',
    'E-mail requerido': 'E-mail requerit',
    'Informe del Ã³rgano responsable (departament, instituto, ...)':
        "Informe de l'Ã²rgan responsable (departament, institut,...",
    'Curriculum de profesorado externo': 'Culliculum de professorat extern',
    'En un Ãºnico fichero zip si son varios':
        'En un Ãºnic fitxer zip si son varis',
    'Otra documentaciÃ³n relevante': 'Altra documentaciÃ³ rellevant',
    'Observaciones adicionales': 'Observacions addicionals',
    'Â¡El fichero no puede exceder de 10MB!':
        'El fitxer no pot superar els 10MB!',
    'Ãrgano proponente del curso': 'Ãrgan proposant del curs',
    'Tipo de Ã³rgano': "Tipus d'Ã²rgan",
    Ãrgano: 'Ãrgan',
    Calendario: 'CalÂ·lendari',
    EdiciÃ³n: 'EdiciÃ³',
    'MÃ¡ximo de estudiantes': "MÃ xim d'estudiants",
    'Inicio / Fin de las clases': 'Inici/final de les classes',
    'Campo autocalculado': 'Camp auto-calculat',
    'Fecha Fin PreinscripciÃ³n': 'Data final de preinscripciÃ³',
    'MÃ­mino 15 dÃ­as antes del inicio del curso':
        "MÃ­nim 15 dies abans de l'inici del curs",
    'Entidades colaboradoras': 'Entitats colÂ·laboradores',
    'LocalizaciÃ³n - Horarios': 'LocalitzaciÃ³ - Horaris',
    'Recuerda que debes crear el plan de estudios con el mismo idioma elegido para el tÃ­tulo propio.':
        "Recorda que deus crear el plÃ  d'estudis amb el mateix idioma elegit per al tÃ­tol propi",
    'Modalidad de imparticiÃ³n': "Modalitat d'imparticiÃ³",
    Idioma: 'Idioma',
    'Lugar de imparticiÃ³n': "Lloc d'imparticiÃ³",
    'DÃ­as de imparticiÃ³n y horarios': "Dies d'imparticiÃ³ i horaris",
    'El tipo de Ã³rgano es requerido': "El tipus d'Ã²rgan Ã©s un camp requerit",
    'El Ã³rgano es requerido': "L'Ã²rgan Ã©s un camp requerit",
    'La fecha es requerida': 'La data Ã©s un camp requerit',
    'No puede ser menor que el nÃºmero mÃ­nimo de estudiantes':
        "No pot ser menor que el nombre mÃ­nim d'estudiants",
    'El presupuesto del tÃ­tulo vinculado se gestiona desde el tÃ­tulo "padre"':
        'El pressupost del tÃ­tol vinculat es gestiona des del tÃ­tol"pare"',
    'Total Prespuesto': 'Total Pressupost',
    'Total Ingresos': 'Total Ingressos',
    'Total Gastos': 'Total Despeses',
    Superavit: 'Superavit',
    DÃ©ficit: 'DÃ¨ficit',
    'Desglose Ingresos': 'RelaciÃ³ ingressos',
    'NÃºmero mÃ­nimo de estudiantes': "Nombre mÃ­nim d'estudiants",
    Patrocinios: 'Patrocinis',
    'Ingresos por hijos': 'Ingressos per fills',
    'Salarios DirecciÃ³n ': 'Salaris DirecciÃ³',
    Salario: 'Salari',
    'Desglose de gastos': 'RelaciÃ³ de despeses',
    'Canon universitario': 'CÃ non universitari',
    'Porcentaje GestiÃ³n Universitaria': 'Percentatge GestiÃ³ UniversitÃ ria',
    'Puedes especificar uno distinto del general del':
        'Pots especificar un diferent del general del',
    'GestiÃ³n universitaria': 'GestiÃ³ universitÃ ria',
    'Fondo de becas': 'Fons de beques',
    Profesorado: 'Professorat',
    'DirecciÃ³n (hasta ': 'DirecciÃ³ (fins ',
    'Material docente': 'Material docent',
    'Viajes y dietas': 'Viatges i dietes',
    Otros: 'Altres',
    Seguros: 'Segurs',
    'Publicidad y promociÃ³n': 'Publicitat i promociÃ³',
    'Actividades complementarias': 'Activitats complementÃ ries',
    'Medios e instalaciones': 'Mitjans i instalÂ·lacions',
    TÃ­tulo: 'TÃ­tol',
    'MÃ¡ximo ${this.constants.OTHER_AMOUNT_MAX_PERCENT}% del total de ingresos':
        "MÃ xim ${this.constants.OTHER_AMOUNT_MAX_PERCENT}% del total d'ingressos",
    'La suma de salarios excede el mÃ¡ximo permitido':
        'La suma dels salaris excedeix el mÃ xim permÃ©s',
    Asociar: 'Associar',
    'al tÃ­tulo propio': 'al tÃ­tol propi',
    'AÃ±adir Director o Directora al tÃ­tulo': 'Afegir Director o Directora al tÃ­tol',
    'Escribe nombre, apellidos o NIF para elegir un Director UV':
        'Escriu nom, cognoms o NIF per elegir un/a Director/a UV',
    'Escribe nombre, apellidos o NIF para elegir un Director o Directora Externo/a':
        'Escriu nom, cognoms o NIF per elegir un/a Director/a extern',
    'Director/Directora UV': 'Director/a UV',
    'Director/Directora Externo': 'Director/a Extern/a',
    'Curso acadÃ©mico para el tÃ­tulo': 'Curs acadÃ¨mic per al tÃ­tol',
    'DuraciÃ³n en cursos acadÃ©micos del tÃ­tulo':
        'DuraciÃ³ en cursos acadÃ¨mics del tÃ­tol',
    'No se recomienda superar los 60 ECTS por curso acadÃ©mico':
        'No es recomana superar els 60 ECTS per curs acadÃ¨mic',
    'TÃ­tulo vinculado': 'TÃ­tol vinculat',
    'Nombre para el tÃ­tulo propio': 'Nom per al tÃ­tol propi',
    'Profesor/a UV solicitante del tÃ­tulo propio (UV)':
        'Professor/a UV solÂ·licitant del tÃ­tol propi (UV)',
    'Directores o Directoras Universitat de ValÃ¨ncia':
        'Directors o directores de la Universitat de ValÃ¨ncia',
    'AÃ±adir director o directora': 'Afegir director o directora',
    'Directores o Directoras Externos/as (si los hubiera)': 'Directors o Directores externs',
    'AÃ±adir director o directora externo/a': 'Afegir Director o Directora extern/a',
    'Se borrarÃ¡ al profesor de la asignatura':
        "S'esborrarÃ¡ al professor de l'assignatura",
    'El aÃ±o acadÃ©mico es requerido': "Camp 'any acadÃ¨mic' requerit",
    'La duraciÃ³n es requerida': 'La duraciÃ³ Ã©s requerida',
    'El tÃ­tulo es requerido': 'El tÃ­tol Ã©s requerit',
    'El director responsable de la uv es requerido':
        'El director o directora de la UV responsable Ã©s requerit',
    '${this.masterState.master_state.name':
        '${this.masterState.master_state.name',
    'Detalle resumen profesorado uv': 'Detall resum professorat UV',
    'Detalle resumen profesorado externo': 'Detall resum professorat extern',
    DirecciÃ³n: 'DirecciÃ³',
    'Datos bÃ¡sicos': 'Dades bÃ siques',
    'Otros datos': 'Altres dades',
    'Plan de estudios': "PlÃ  d'estudis",
    Claustro: 'Claustre',
    Presupuesto: 'Pressupost',
    Anexos: 'Annexos',
    Presentar: 'Presentar',
    'El tÃ­tulo propio no existe': 'El tÃ­tol propi no existeix',
    'OcurriÃ³ un error ': 'Ha ocorregut una errada',
    'Listado de tÃ­tulos presentados': 'Llistat de tÃ­tols presentats',
    Buscar: 'Cercar',
    'Se produjo un error realizando la operaciÃ³n.':
        "S'ha produÃ¯t una errada realitzant la operaciÃ³",
    'Presentar TÃ­tulo': 'Presentar tÃ­tol',
    'Archivar TÃ­tulo': 'Arxivar tÃ­tol',
    'Aprobar TÃ­tulo': 'Aprovar tÃ­tol',
    'Presenta el tÃ­tulo para su revisiÃ³n por los tÃ©cnicos':
        'Presenta el tÃ­tol per a la seua revisiÃ³ pels tÃ¨cnics',
    'Al ser aprobado, no se permiten modificaciones': 'En ser aprovat, no es permeten modificacions',
    'Marcar el tÃ­tulo como inactivo.': 'Marcar el tÃ­tol com a inactiu.',
    'Marcar el tÃ­tulo como borrador.': 'Marcar el tÃ­tol com a esborrany.',
    'Marcar como Revisado': "Marcar com 'Revisat'",
    'Deja el tÃ­tulo como "correcto" y no permite su posterior ediciÃ³n por parte del director':
        'Deixa el tÃ­tol com "correcte" i no permet la posterior ediciÃ³ per part del director o directora',
    'Al marcar un tÃ­tulo como borrador, este volverÃ¡ a ser editable por el usuario y deberÃ¡ pasar por el flujo normal de aprobaciÃ³n. Quieres continuar?': "En marcar un tÃ­tol com a esborrador, aquest tornarÃ  a ser editable per l'usuari i haurÃ  de passar pel flux normal d'aprovaciÃ³. Vols continuar?",
    'Pedir subsanaciÃ³n': 'Demanar subsanaciÃ³',
    'Pide al director que realice modificaciones sobre el tÃ­tulo.':
        'Demana al director o directora que realitze modificacions sobre el tÃ­tol',
    'Alta en Sigue': 'Alta en Sigue',
    Memoria: 'MemÃ²ria',
    'La bÃºsqueda de ': 'La cerca de ',
    ' no produjo ningÃºn resultado': 'no produeix cam resultat',
    'Alta de tÃ­tulo en SIGUE': 'Alta de tÃ­tol en SIGUE',
    'Vas a traspasar el tÃ­tulo al sistema SIGUE. Una vez traspasado el tÃ­tulo no se podrÃ¡ modificar. Quieres continuar?':
        'Vas a traspassar el tÃ­tol al sistema SIGUE. Una vegada traspassat el tÃ­tol no es podrÃ  modificar. Vols continuar?',
    Si: 'Si',
    Cancelar: 'CancelÂ·lar',
    'Presentar tÃ­tulo': 'Presentar tÃ­tol',
    'Archivar tÃ­tulo': 'Archive degree',
    'Aprobar tÃ­tulo': 'Archive degree',
    'Al presentarlo, el tÃ­tulo serÃ¡ revisado por los tÃ©cnicos y dejarÃ¡ de ser editable para el director. Quieres continuar?':
        'Una vegada presentat, el tÃ­tol serÃ  revisat pels tÃ¨cnics i deixarÃ  de ser editable pel director o directora. Vols continuar?',
    'Marcar tÃ­tulo como revisado': 'Marcar tÃ­tol com revisat',
    'Al marcar un tÃ­tulo como revisado, se dara como correcta la informaciÃ³n asociada pudiendo presentar el tÃ­tulo.  Quieres continuar?':
        'Al marcar un tÃ­tol com a revisat es donarÃ  per correcta la informaciÃ³ associada i es podrÃ  presentar. Vols continuar?',
    'Pedir al director subsanar errores':
        "Demanar al director o directora subsanament d'errades",
    'Al pedir subsanaciÃ³n, el tÃ­tulo pasarÃ¡ de nuevo a estado borrador y el director deberÃ¡ volver a presentarlo. Quieres continuar?':
        'Al demanar una subsanaciÃ³, el tÃ­tol passarÃ  a estat esborrany i la direcciÃ³ deurÃ  tornar a presentar-lo. Vols continuar?',
    'TÃ­tulo padre': 'TÃ­tol pare',
    Director: 'Director o Directora',
    Centro: 'Centre',
    ECTS: 'ECTS',
    Tipo: 'Tipus',
    'F. Fin PreinscripciÃ³n': 'D. Final de preinscripciÃ³',
    Estado: 'Estat',
    'En Sigue': 'En SIGUE',
    'Relevancia acadÃ©mica': 'RellevÃ ncia acadÃ¨mica',
    'Objetivos del curso': 'Objectius del curs',
    'Requisitos de acceso y perfil de ingreso': "Requisits d'accÃ©s i perfil d'ingrÃ©s",
    'Salidas profesionales y/o perfil de egreso': 'Eixides professionals i/o perfil d\'ingrÃ©s',
    'MetodologÃ­a general': 'MetodologÃ­a general',
    'Ãmbitos de conocimiento': 'Ãmbits de coneixement',
    'InformaciÃ³n para publicidad': 'InformaciÃ³ per a publicitat',
    'InformaciÃ³n para publicidad: esta informaciÃ³n aparecerÃ¡ en la web': 'InformaciÃ³ per a publicitat: aquesta informaciÃ³ apareixerÃ  en la web',
    'Rama de conocimiento': 'Branca de coneixement',
    'Escribe aquÃ­ el interÃ©s y la relevancia acadÃ©mica cientÃ­fica y profesional del curso':
        "Escriga acÃ­ l'interÃ¨s i la rellevÃ ncia acadÃ¨mica, cientÃ­fica i professional del curs",
    'Escribe aquÃ­ los objetivos del curso':
        'Escriga acÃ­ els objectius del curs',
    'Escribe aquÃ­ el perfil de estudiantes y requisitos de acceso':
        "Escriga acÃ­ el perfil de lestudiantat i els requisits d'accÃ¨s",
    'Escribe aquÃ­ las salidas profesionales del curso':
        'Escriga acÃ­ les eixides professionals del curs',
    'Escribe aquÃ­ la metodologÃ­a general del curso':
        'Escriga acÃ­ la metodologÃ­a general del curs',
    'No se puede presentar el tÃ­tulo.  AlgÃºn paso no ha sido completado correctamente.':
        'No es pots presentar el tÃ­tol. Algun pas no ha sigut completat',
    'Presentar el tÃ­tulo': 'Presentar el tÃ­tol',
    'El tÃ­tulo estÃ¡ ': 'El tÃ­tol estÃ ',
    'Detalle - Asignaturas': 'Detall - Assignatures',
    'Resumen de horas impartidas y ECTS': "Resum d'hores impartides i ECTS",
    'Actualiza la lista de asignaturas del tÃ­tulo vinculado':
        "Actualitza la llista d'assignatures del tÃ­tol vinculat",
    'Actualizar asignaturas': 'Actualitzar assignatures',
    Nombre: 'Nom',
    'Tipo docencia': 'Tipus de docÃ¨ncia',
    PrÃ¡cticas: 'PrÃ ctiques',
    'Crear asignatura': 'Crear una assignatura',
    'Modificar asignatura': 'Modificar una assignatura',
    'Nombre de la asignatura': "Nom de l'assignatura",
    'AÃ±o del tÃ­tulo en el que se imparte la asignatura':
        "Any del tÃ­tol en el qual s'imparteix l'assignatura",
    'Tipo de docencia': 'Tipus de docÃ¨ncia',
    'Es trabajo fÃ­n de master': 'Ãs treball final de mÃ ster',
    'El master ya tiene una asignatura fin de master.':
        'El mÃ ster ja te una assignatura final de mÃ ster',
    'Es asignatura de pÅacticas (curriculares)': 'Ãs assignatura de prÃ ctiques',
    'El nombre es requerido': 'El nom Ã©s requerit',
    'El tipo de docencia es requerido': 'El tipus de docÃ¨ncia Ã©s requerit',
    'El nÃºmero de ECTS es requerido': 'El nombre de ECTS Ã©s requerit',
    'El nÃºmero mÃ­mino de ECTS es ': 'El nombre mÃ­nim  de ECTS Ã©s',
    'El nÃºmero mÃ­mino de ECTS para el TFM es ':
        'El nombre mÃ­nim de ECTS per al TFM Ã©s',
    'SÃ³lo se admite medio ECTS': "Sols s'admitexi mig ECTS",
    'El campo es requerido': 'El camp Ã©s requerit',
    'Detalle plan de estudios': "Detall plÃ  d'estudis",
    'AÃ±adir nueva asignatura': 'Afegir nova assignatura',
    'Actualiza asignaturas del tÃ­tulo vinculado':
        'Actualitza assignatures del tÃ­tol vinculat',
    'Eliminar asignatura': 'Eliminar assignatura',
    'Eliminar tÃ­tulo': 'Eliminar TÃ­tol',
    'GuÃ­a docente': 'Guia docent',
    Profesores: 'Professorat',
    'Total AÃ±o ': 'Total Any',
    Total: 'Total',
    'Se eliminarÃ¡ la asignatura completa, con toda la informaciÃ³n de profesores y plan de estudios.':
        "S'eliminarÃ  l'assignatura completa. amb tota la informaciÃ³ de professorat i plÃ  d'estudis.",
    AÃ±o: 'Any',
    'Horas min.': 'Hores mÃ­n.',
    'Horas max': 'Hores mÃ x.',
    'Horas asignadas': 'Hores assignades',
    'Detalle profesorado asignatura: ': 'Detall professorat assignatura: ',
    'Volver a la lista de asignaturas': "Tornar a la llista d'assignatures",
    'Asociar profesor': 'Associar professorat',
    'Hs. MÃ­minas': 'Hs. MÃ­nimes',
    'Hs. MÃ¡ximas': 'Hs. MÃ¡ximes',
    'Total Asignadas: ': 'Total Assignades:',
    'Modificar datos': 'Modificar dades',
    'Eliminar asociaciÃ³n con profesor': "Eliminar l'associaciÃ³ amb professorat",
    Profesor: 'Professor o professora',
    Origen: 'Origen',
    Responsable: 'Responsable',
    'Docencia teÃ³rica': 'DocÃ¨ncia teÃ²rica',
    Horas: 'Hores',
    'â¬/hora': 'â¬/hora',
    'Docencia prÃ¡ctica': 'DocÃ¨ncia prÃ ctica',
    'TutorÃ­as obligatorias': 'Tutories obligatÃ²ries',
    'GuÃ­a docente de la Asignatura:': "Guia docent de l'assignatura",
    'Resumen de la asignatura': "Resumen de l'assignatura",
    'Resultado del aprendizaje de la asignatura':
        "Resultat de l'aprenentatge de l'assignatura",
    'MetodologÃ­a del aprendizaje': "Metodologia de l'aprenentatge",
    'Sistemas de evaluaciÃ³n de la asignatura':
        "Sistemes d'avaluaciÃ³ de l'assignatura",
    'BibliografÃ­a de la asignatura': "BibliografÃ­a de l'assignatura",
    'Descriptores del temario de la asignatura':
        "Descriptors del temari de l'assignatura",
    'Inicio de la docencia': 'Inici de la docÃ¨ncia',
    'FinalizaciÃ³n de la docencia': 'FinalitzaciÃ³ de la docÃ¨ncia',
    'Escribe aquÃ­ el resumen de la asignatura':
        "Escriga acÃ­ el resul de l'assignatura",
    'Escribe aquÃ­ el resultado del aprendizaje':
        "Escriga acÃ­ el resultat de l'aprenentatge",
    'Escribe aquÃ­ la metodologÃ­a de aprendizaje de la asignatura':
        "Escriga acÃ­ la metodologia d'aprenentatge de l'assignatura",
    'Escribe aquÃ­ los sistemas de evaluaciÃ³n de la asignatura':
        "Escriga acÃ­ els sistemes d'avaluaciÃ³ de l'assignatura",
    'Escribe aquÃ­ la biblografÃ­a de la asignatura':
        "Escriga acÃ­ la bibliografia de l'assignatura",
    'Escribe aquÃ­ los descriptores del temario de la asignatura':
        "Escriga acÃ­ els descriptors del temari de l'assignatura",
    'El temario no puede estar vacÃ­o.': 'El temari no pot estar buit',
    'El tÃ­tulo es un ': 'El tÃ­tol Ã©s un',
    'El tÃ­tulo no tiene asignado ningÃºn tipo de titulaciÃ³n':
        'El tÃ­tol no tÃ© assignat cap tÃ­pus de titulaciÃ³',
    ' AÃ±o ': 'Any',
    'Nuevo Profesor': 'Nou professor o professora',
    'Modificar profesor': 'Modificar professor o professora',
    Apellidos: 'Cognoms',
    NIF: 'NIF',
    'NIF sin guiones, sÃ³lo nÃºmeros y letra':
        'NIF sense guins, sols nombres i lletra',
    'E-mail': 'E-mail',
    'Fecha de nacimiento': 'Data de naixement',
    TelÃ©fono: 'TelÃ¨fon',
    'Es un profesor de otra universidad distinta a la UV':
        "Ãs un professor o professora d'altra universitat distinta a la UV",
    'Es un profesor que trabaja en una empresa':
        'Ãs un professor o professora que treballa a una empresa',
    'Es persona fÃ­sica': 'Ãs persona fÃ­sica',
    'Nuevo Profesor UV': 'Nou professor o professora UV',
    'Modificar profesor UV': 'Modificar professor o professora UV',
    Departamento: 'Departament',
    'CategorÃ­a profesional': 'Categoria professional',
    'Ãrea de conocimiento': 'Ãrea de coneixemenet',
    'Asociar Profesor a asignatura': 'Associar professorat a assignatura',
    'Modificar RelaciÃ³n Profesor - Asignatura':
        'Modificar relaciÃ³ professorat - assignatura',
    'Nuevo Profesor Externo': 'Nou professor/a extern',
    'Modificar datos profesor externo': 'Modificar dades professorat extern',
    'Nuevo P.D.I/P.A.S UV': 'Nou P.D.I/P.A.S. UV',
    'Modificar datos P.D.I/P.A.S UV': 'Modificar dades P.D.I/P.A.S UV',
    'Elige un profesor UV': 'Elegix un professor o una professora UV',
    'Elige un profesor externo a la UV':
        'Elegix un professor o una professora externa a la UV',
    'Es responsable': 'Ãs responsable',
    'TTodas las asignaturas tienen un profesor responsable que es la persona que firmarÃ¡ el acta. Caso de no ser profesor uv, el acta la firmarÃ¡ el director':
        "Totes les assignatures tenen un/a professor/a responsable que Ã©s la persona que signarÃ  l'acta. Si no Ã©s de la UV, l'acta l'ha de signar el director o directora",
    'NÃºmero de horas': "Nombre d'hores",
    'â¬ / hora': 'â¬ / hora',
    'El profesor ya estÃ¡ asociado a la asignatura':
        "El/la professor/a ja estÃ  associat a l'assignatura",
    'SÃ³lo se admiten medias horas': "Sols s'admeten miges hores",
    'SÃ³lo se admiten mitades (0,5)': "Sols s'admeten miges (0,5)",
    'Superado el mÃ¡ximo permitido de': 'Superat el mÃ xim permÃ¨s de',
    'Coste Total profesorado': 'Cost total professorat',
    'Horas Docencia ': 'Hores docÃ¨ncia',
    '% horas docencia profesorado ': '% hores docÃ¨ncia professorat',
    'Coste Total': 'Cost total',
    'Crear tÃ­tulo propio vinculado': 'Crear tÃ­tol propi vinculat',
    "Elige el tÃ­tulo propio 'padre'": "Elegix tÃ­tol propi 'pare'",
    "Nombre para el nuevo tÃ­tulo propio vinculado ('hijo')":
        "Nom per al nou tÃ­tol propi vinculat ('fill')",
    'El nombre debe ir precedido del tipo de tÃ­tulo (Master de FormaciÃ³n Permanente en..., Experto universitario en...). Ejemplos:':
        "El nom ha d'anar precedit del tipus de tÃ­tol (MÃ ster de FormaciÃ³ Permanet en ..., Expert universitari en...) Exemples:",
    'El nombre debe ir precedido del tipo de tÃ­tulo (Microcredencial en..., Certificado Universitario en...). Ejemplos:': "El nom ha d'anar precedit del tipus de tÃ­tol (Microcredencial en ..., Expert universitari en...) Exemples:",
    'Diploma de EspecializaciÃ³n': "Diploma d'especialitzaciÃ³",
    'Derecho de la economÃ­a digital': 'Dret de la economia digital',
    'Certificado Universitario ': 'Certificat de formaciÃ³ contÃ­nua',
    'Documentos digitales y archivos': 'Documents digitals i arxius',
    'Experto Universitario': 'Expert Universitari',
    'en "Startup Management"': 'en"Startup Management"',
    'No puede ser mayor que el nÃºmero mÃ¡ximo de estudiantes':
        "No pot ser major que el nombre mÃ xim d'estudiants",
    'Crear tÃ­tulo propio': 'Crear tÃ­tol propi',
    'Crear tÃ­tulo': 'Crear tÃ­tol',
    'Crear tÃ­tulo - FormaciÃ³n ContÃ­nua': 'Crear tÃ­tol - FormaciÃ³ ContÃ­nua',
    'Crear TÃ­tulo de FormaciÃ³n ContÃ­nua': 'Crear TÃ­tol de FormaciÃ³ ContÃ­nua',
    'Curso acadÃ©mico de inicio para el tÃ­tulo':
        "Curs acadÃ¨mic d'inici per al tÃ­tol",
    'Master de formaciÃ³n permanente en ': 'MÃ ster de formaciÃ³ permanet  en',
    "Microcredential ": "Microcredencial",
    'Terapia Manual: Enfoque- Neuro-OrtopÃ©dico ClÃ­nico':
        'TerÃ pia Manual: Enfocament Neuro-OrtoÃ¨dic ClÃ­nic',
    'Fichero vacÃ­o': 'Fitxer buit',
    'master unknown': 'mÃ ster unknown',
    'Asignaturas obligatorias': 'Assignatures obligatÃ²ries',
    'PrÃ¡cticas Externas': 'PrÃ ctiques Externes',
    'Trabajo Final de MÃ¡ster (TFM)': 'Treball Final de MÃ ster (TFM)',
    EspaÃ±ol: 'CastellÃ ',
    ValenciÃ : 'ValenciÃ¡',
    Draft: 'Esborrany',
    Revised: 'Revisat',
    Published: 'Presentat',
    Approved: 'Aprovat',
    Archived: 'Arxivat',
    'published with errors': 'Publicat amb errades',
    Correcting: 'Esmena',
    Corrected: 'Esmenat',
    'Master': 'MÃ ster de FormaciÃ³ Permanent',
    'University Expert': 'Expert Universitari',
    'Specialization Diploma': "Diploma d'EspecialitzaciÃ³",
    'University Certificate': 'Certificat Universtari FormaciÃ³ ContÃ­nua',
    Center: 'Centre',
    Department: 'Departament',
    'Research Institute': "Institut d'InvestigaciÃ³",
    'Art and Humanities': 'Arts i Humanitats',
    Science: 'CiÃ¨ncies',
    'Health Science': 'CiÃ¨ncies de la Salut',
    'Social and Legal Science': 'CiÃ¨ncies Socials i jurÃ­diques',
    'Engineering and Architecture': 'Enginyeria i Arquitectura',
    'Business Management': 'DireccciÃ³ i GestiÃ³ empresarial',
    'Social and Legal': 'JurÃ­dic i Social',
    Humanities: 'Humanitats',
    'Education Science': "CiÃ¨ncies de l'educaciÃ³",
    Psychology: 'Psicologia',
    'Environmental Health and Security': 'Seguretat, Salut i Mediambient',
    Health: 'Salut',
    'Science and Technology': 'CiÃ¨ncia i tecnologia',
    'Physical Activity and Sport': 'Activitat fÃ­sica i esport',
    Spanish: 'CastellÃ ',
    English: 'AnglÃ¨s',
    'Online master': 'No presencial (on-line)',
    'Face To Face': 'Presencial',
    'Semi Face To Face': 'Semi-presencial',
    'CÃ³digo UV del tÃ­tulo': 'Codi UV del tÃ­tol',
    'Otro Ã³rgano proponente del curso (OPCIONAL)':
        'Altre Ã²rgan proposant del curs (OPCIONAL)',
    'Dado que se trata de un profesor UV no funcionario, contemplar en los honorarios del profesor el pago destinado a la seguridad social.': `AtÃ©s que es tracta d'un professor UV no funcionari, contemplar en els honoraris del professor el pagament destinat a la seguretat social.`,
    'Un curso acadÃ©mico': 'Un curs acadÃ¨mic',
    'Dos cursos acadÃ©micos': 'Dos cursos acadÃ¨mics',
    'Tres cursos acadÃ©micos': 'Tres cursos acadÃ¨mics',
    'Un fichero pdf': 'Un fitxer pdf',
    'Curso 2021/2022': 'Curs 2021/2022',
    'Acuerdo para realizaciÃ³n de tÃ­tulos propios (profesorado UV)':
        'Acord per a realitzaciÃ³ de tÃ­tols propis (professorat UV)',
    'No se puede presentar el tÃ­tulo. AlgÃºn paso no ha sido completado correctamente.':
        'No es pot presentar el tÃ­tol. Algun pas no ha sigut completat correctament.',
    ' Modificar / Revisar tÃ­tulo': 'Modificar / Revisar tÃ­tol',
    'Se eliminarÃ¡ el tÃ­tulo con todos sus datos. EstÃ¡s seguro/a?':
        "S'eliminarÃ  el tÃ­tol amb totes les seues dades. EstÃ s segur/a?",
    'El plazo de presentaciÃ³n ha finalizado.':
        'El termini de presentaciÃ³ ha finalitzat',
    'Agrupar padres e hijos': 'Agrupar pares i fills',
    'El tipo de fichero es obligado': 'El tipus de fitxer Ã©s obligat',
    'Modelo 83': 'Model 83',
    'CurrÃ­culums profesores externos': 'CurrÃ­culums professors externs',
    'Puedes obtener un fichero zip con todos los ficheros del modelo 83 de todos los tÃ­tulos de un curso acadÃ©mico pinchando en "Generar Fichero"':
        'Pots obtindre un fitxer zip amb tots els fitxers del model 83 de tots els tÃ­tols d\'un curs acadÃ¨mic punxant en "Generar Fichero"',
    'Filtrar por curso acadÃ©mico': 'Filtrar per curs acadÃ¨mic',
    'Importar tÃ­tulo de otro curso acadÃ©mico':
        "Importar tÃ­tol d'un altre curs acadÃ¨mic",
    'Curso acadÃ©mico': 'Curs acadÃ¨mic',
    "Selecciona un tÃ­tulo de la lista y pincha en 'Crear tÃ­tulo'":
        "Selecciona un tÃ­tol de la llista i punxa a 'Crear tÃ­tol'",
    'TÃ­tulo importado con Ã©xito': 'TÃ­tol importat amb Ã¨xit',
    'Se crearÃ¡ un nuevo tÃ­tulo idÃ©ntico al seleccionado para el curso acadÃ©mico actual. Quieres continuar?':
        'Es crearÃ  un nou tÃ­tol idÃ¨ntic al seleccionat per al curs acadÃ¨mic actual. EstÃ s segur?',
    'RevisiÃ³n de cambios': 'RevisiÃ³ de canvis',
    'Orden': 'Ordre',
    'Requerido': 'Requerit',
    'Abajo': 'A baix',
    'Arriba': 'A dalt',
    'Pincha y arrastra la fila para ordenar': 'Punxa i arrossega la fila per a ordenar',
    'Precio general': 'Preu general',
    'Escribe una contraseÃ±a para tu cuenta': 'Escriu una nova contrasenya per al teu compte',
    'Error estableciendo la contraseÃ±a': 'Error al canviar la contrasenya',
    'Vuelve a introducir la contraseÃ±a': 'Torna a escriure la contrasenya',
    'Establecer contraseÃ±a': 'Establir contrasenya',
    'La contraseÃ±a se ha establecido correctamente. Ya puedes iniciar sesiÃ³n en la aplicaciÃ³n Solicitudes para gestionar tus tÃ­tulos.': 'La contrasenya s\'ha establit correctament. Ja pots iniciar sessiÃ³ en l\'aplicaciÃ³ SolÂ·licituds per a gestionar els teus tÃ­tols.',
    'Solicitud de cambio de contraseÃ±a': 'SolÂ·licitud de canvi de contrasenya',
    'Esta opciÃ³n sÃ³lo es vÃ¡lida para usuarios externos a UV.': 'Aquesta opciÃ³ nomÃ©s Ã©s vÃ lida per a usuaris externs a UV.',
    'Error al solicitar cambio de contraseÃ±a': 'Error en solÂ·licitar canvi de contrasenya',
    'Correo electrÃ³nico de acceso de la aplicaciÃ³n': 'Correu electrÃ²nic d\'accÃ©s de l\'aplicaciÃ³',
    'Enviar solicitud de cambio de contraseÃ±a': 'Enviar solÂ·licitud de canvi de contrasenya',
    'He olvidado mi contraseÃ±a': 'He oblidat la meua contrasenya',
    'Las contraseÃ±as deben cumplir los siguientes requisitos:': 'Les contrasenyes han de complir els segÃ¼ents requisits:',
    'Debe tener al menos 6 caracteres': 'Ha de tindre almenys 6 carÃ cters',
    'Debe contener una mayÃºscula': 'Ha de contindre una majÃºscula',
    'Debe contener un nÃºmero': 'Ha de contindre un nÃºmero',
    'Debe contener un carÃ¡cter especial [!@#$%*]': 'Ha de contindre un carÃ cter especial [!@#$%*]',
    'Correo electrÃ³nico':'Correu electrÃ²nic',
    'Se ha enviado un correo a tu cuenta con el enlace para establecer tu contraseÃ±a': 'S\'ha enviat un correu al teu compte amb l\'enllaÃ§ per a establir la teua contrasenya',
    'SincronizaciÃ³n con Sigue': 'SincronitzaciÃ³ amb Sigue',
    'TEACHER_OTHER_UNIV': 'Professors d\'altres universitats',
    'TEACHER_EXTERNAL': 'Professors Externs',
    'CENTER': 'Centres',
    'TEACHER_UV': 'Professors UV',
    'COMPANY': 'Empreses',
    'DEPARTMENT': 'Departaments',
    'PROFESSIONAL_CATEGORY': 'Categoria Professional',
    'Nuevos': 'Nous',
    'Modificados': 'Modificats',
    'Refrescar': 'Refrescar',
    'Generar ficheros Zip': 'Generar fitxers zip',
    'No existen sincronizaciones.': 'No existeixen sincronitzacions.',
    'Copiar datos desde Sigue': 'Copiar dades des de Sigue',
    'Esta operaciÃ³n tardarÃ¡ un poco. Ten paciencia...': 'Aquesta operaciÃ³ tardarÃ  una mica. Tingues paciÃ¨ncia...',
    'Volcar datos a Solicitudes': 'Bolcar dades a SolÂ·licituds',
    'Una vez comprobados los datos que vas a actualizar (nuevos y modificaciones), quieres volcar esta informaciÃ³n a la base de datos de Solicitudes. Esta acciÃ³n no tiene vuelta atrÃ¡s. Quieres continuar?': 'Una vegada comprovats les dades que actualitzarÃ s (nous i modificacions), vols bolcar aquesta informaciÃ³ a la base de dades de SolÂ·licituds. Aquesta acciÃ³ no tÃ© volta arrere. Vols continuar?',
    'Se copiaron los datos con Ã©xito': 'Es van copiar les dades amb Ã¨xit',
    'SincronizaciÃ³n iniciada el ': 'SincronitzaciÃ³ iniciada el ',
    'Ãltima sincronizaciÃ³n realizada el ': 'Ãltima sincronitzaciÃ³ realitzada el ',
    'Nueva sincronizaciÃ³n (volver a copiar datos desde Sigue)': 'Nova sincronitzaciÃ³ (tornar a copiar dades des de Sigue)',
    'Pendiente de volcar a solicitudes.': 'Pendent de bolcar a solÂ·licituds.',
    'Volver a copiar datos desde Sigue': 'Tornar a copiar dades des de Sigue',
    'Toda la informaciÃ³n estÃ¡ sincronizada entre Sigue y Solicitudes.': 'Tota la informaciÃ³ estÃ  sincronitzada entre Sigue i SolÂ·licituds.',
    'Generar Fichero': 'Generar fitxer',
    'Tipo de Fichero': 'Tipus de fitxer',
    "Ejemplo: MÃ©dico forense": "Exemple: MÃ¨dic forense",
    "Universidad": "Universitat",
    "Si no encuentras la universidad que buscas, puedes enviar un correo solicitando su creaciÃ³n a": "Si no trobes la universitat que cerques, pots enviar un correu solÂ·licitant la seua creaciÃ³ a",
    "Cargo": "CÃ rrec",
    "CategorÃ­a / Cargo": "Categoria / CÃ rrec",
    "Cargo en la empresa": "CÃ rrec en l'empresa",
    "La categorÃ­a profesional del director del tÃ­tulo no permite presentar este tipo de tÃ­tulo.": "La categoria professional del director del tÃ­tol no permet presentar aquest tipus de tÃ­tol",
    "El tÃ­tulo no se podrÃ¡ presentar ya que la categorÃ­a profesional del director del tÃ­tulo (": "El tÃ­tol no es podrÃ  presentar ja que la categoria professional del director del tÃ­tol (",
    ") no permite crear tÃ­tulos de tipo": ") no permet crear tÃ­tols de tipus",
    'No se puede establecer el tipo de tÃ­tulo a partir de sus ECTS. Revisa las asignaturas para que a partir de la suma de ECTS pueda establecerse el tipo de tÃ­tulo.': 'No es pot establir el tipus de tÃ­tol a partir dels seus ECTS. Revisa les assignatures perquÃ¨ a partir de la suma de ECTS puga establir el tipus de tÃ­tol.',
    'No se puede publicar el tÃ­tulo. ': 'No es pot publicar el tÃ­tol. ',
    'No todos los directores son profesores.': 'No tots els directors sÃ³n professors.',
    "Es modular": "Ãs modular",
    "Es mÃ³dulo del tÃ­tulo...": "Ãs mÃ²dul del tÃ­tulo...",
    "Es mÃ³dulo de ": "Ãs mÃ²dul de ",
    "Solo se muestran tÃ­tulos modulares": "NomÃ©s es mostren tÃ­tols modulars",
    "Un tÃ­tulo modular necesita al menos dos tarifas. Una \"general\" y otra para el caso de cursarlo por mÃ³dulos.": "Un tÃ­tol modular necessita almenys dos tarifes. Una \"general\" i una altra per al cas de cursar-ho per mÃ²duls",
    "Asignaturas optativas a cursar": "Assignatures optatives a cursar",
    "ECTS por asignatura Optativa": "ECTS per assignatura Optativa",
    "Para poder asociar al tÃ­tulo asignaturas optativas, se requiere autorizaciÃ³n. Ponte en contacto en ": "Per a poder associar al tÃ­tol assignatures optatives, es requerix autoritzaciÃ³. Posa't en contacte en ",
    "Optativa": "Optativa",
    "FORMACIÃN CONTÃNUA": "FORMACIÃ CONTÃNUA",
    "TÃ­tulos Propios y FormaciÃ³n ContÃ­nua UV": "TÃ­tols Propis i FormaciÃ³ ContÃ­nua UV",
    "Crear TÃ­tulo Propio": "Crear TÃ­tol Propi",
    "MÃ¡ster de formaciÃ³n permanente, Diploma de especializaciÃ³n, Experto Universitario": "MÃ ster de formaciÃ³ permanent, Diploma d'especialitzaciÃ³, Expert Universitari",
    "Microcredencial, Certificado": "Microcredencial, Certificat",
    "El tÃ­tulo no tiene ninguna asignatura.": "El tÃ­tol no tÃ© cap assignatura",
    "Configurar asignaturas optativas": "Configurar assignatures optatives",
    "Configurar optativas": "Configurar optatives",
    "Al menos un codirector debe tener la categorÃ­a profesional adecuada para presentar el tÃ­tulo.": "Almenys un codirector ha de tindre la categoria professional adequada per a presentar el tÃ­tol.",
    "Para poder marcar una asignatura como Optativa, primero configura las asignaturas optativas en la pantalla anterior.": "Per a poder marcar una assignatura com a Optativa, primer configura les assignatures optatives en la pantalla anterior. ",
    "DeclaraciÃ³n responsable de la direcciÃ³n sobre claustro": "DeclaraciÃ³ responsable de la direcciÃ³ sobre el claustre",
    "Puedes descargar la plantilla del documento \"DeclaraciÃ³n responsable de la direcciÃ³n sobre claustro\" ya generada con los datos del tÃ­tulo, pinchando en el botÃ³n \"Generar Modelo\".": "Pots descarregar la plantilla del document \"DeclaraciÃ³ responsable de la direcciÃ³ sobre claustre\" ja generada amb les dades del tÃ­tol, punxant en el botÃ³ 'Generar Model'.",
    "Una vez generado, deberÃ¡s firmar el documento con tu firma digital y subirlo a esta plataforma.": "Una vegada generat, haurÃ s de signar el document amb la teua signatura digital i pujar-lo a aquesta plataforma.",
    "Para firmar un documento con tu firma digital, puedes utilizar varias aplicaciones:": "Per a signar un document amb la teua signatura digital, pots utilitzar diverses aplicacions:",
    "Siguiendo estas instrucciones parar firmar.": "Seguint aquestes instruccions per a signar.",
    "Siguiendo los pasos solicitados al arrancar la aplicaciÃ³n.": "Seguint els passos solÂ·licitats en arrancar l'aplicaciÃ³.",
    "Convertir a formaciÃ³n contÃ­nua": "Convertir a formaciÃ³ contÃ­nua",
    "Escribe aquÃ­ para filtrar": "Escriu acÃ­ per a filtrar",
    "Elige uno o varios estados": "Tria un o mÃ©s estats",
    "TÃ­tulos": "TÃ­tols",
    "DEFAULT": "TÃ­tol propi",
    "CONTINOUS_LEARNING": "FormaciÃ³ contÃ­nua",
    "La suma de salarios excede el mÃ¡ximo permitido": "La suma de salaris excedeix el mÃ xim permÃ©s",
    "Servicios E-learning Adeit": "Serveis elearning Adeit",
    "Profesores inactivos": "Professors inactius",
    "Inactivo": "Inactiu",
    "El profesor o profesora estÃ¡ inactivo o inactiva actualmente. Elige de la lista el mismo profesor o profesora, pero con la informaciÃ³n actualizada.": "El professor o professora estÃ  inactiu o inactiva actualment. Tria de la llista el mateix professor o professora, perÃ² amb la informaciÃ³ actualitzada.",
    "El profesor o profesora estÃ¡ inactivo o inactiva": "El professor o professora estÃ  inactiu o inactiva actualment",
    "Elige un profesor o una profesora de la lista": "Tria un professor o professora de la llista",
    "Elige el mismo profesor o profesora, pero con la informaciÃ³n actualizada": "Tria el mateix professor o professora, , perÃ² amb la informaciÃ³ actualitzada ",
    "AlgÃºn director o directora del tÃ­tulo no se encuentra activo actualmente. Elimina al director o directora marcado como 'inactivo' y vuÃ©lvelo a aÃ±adir con sus datos actualizados. En caso de que no exista el director o directora pÃ³ngase en contacto con el soporte tÃ©cnico.": "Algun director o directora del tÃ­tol no es troba actiu actualment. Elimina al director o directora marcat com a \"inactiu\" i torna'l a afegir amb les seues dades actualitzades. En cas que no existisca el director o directora pose's en contacte amb el suport tÃ¨cnic.",
    "Copiar un tÃ­tulo propio del curso acadÃ©mico anterior": "Copiar un tÃ­tol propi del curs acadÃ¨mic anterior",
    "Copiar un tÃ­tulo de formaciÃ³n contÃ­nua del curso acadÃ©mico anterior": "Copiar un tÃ­tol de formaciÃ³ contÃ­nua del curs acadÃ¨mic anterior",
    "Marcar tÃ­tulo como archivado": "Marcar tÃ­tol com arxivat",
    "Marcar tÃ­tulo como borrador.": "Marcar tÃ­tol com a esborrany.",
    'Marcar como Borrador': 'Marcar com a Esborrany',
    "Marcar tÃ­tulo como aprobado": "Marcar tÃ­tol com aprovado",
    "Al archivar un tÃ­tulo, este quedarÃ¡ como inactivo, no pudiendo realizar modificaciones sobre Ã©l. Quieres continuar?": "En arxivar un tÃ­tol, aquest quedarÃ¡ com a inactiu, no podent realitzar modificacions sobre ell. Vols continuar?",
    "Aprobar un tÃ­tulo supone que todos los datos has sido revisados y aprobados como definitivos. No se permitirÃ¡n modificaciones posteriores. Quieres continuar?": "Aprovar un tÃ­tol suposa que totes les dades has sigut revisats i aprovats com a definitius. No es permetran modificacions posteriors. Vols continuar?",
    "Profesorado - Horas Curso AcadÃ©mico": "Professorat - Hores Curs AcadÃ¨mic",
    'Detalle de asignaturas(en rojo no suman al estar vinculadas a otro tÃ­tulo)': "Detall d'assignatures(en roig no sumen en estar vinculades a un altre tÃ­tol)",
    "Generar todas mas memorias(pdf)": "Generar totes les memÃ²ries(pdf)",
    "NÃ­vel MECES/EQF": "NÃ­vell MECES/EQF",
    "Criterios de admisiÃ³n": "Criteris d'admissiÃ³",
    "Escribe aquÃ­ los criterios de admisiÃ³n": "Escriu acÃ­ els criteris d'admissiÃ³",
    "Resultados del proceso de formaciÃ³n y aprendizaje": "Resultats del procÃ©s de formaciÃ³ i aprenentatge",
    "Para cumplir con las exigencias del RD-L 2/2023, de 16 de marzo (DisposiciÃ³n adicional quincuagÃ©sima segunda), en el caso de los cursos que piden la realizaciÃ³n de prÃ¡cticas en empresas, se deberÃ¡ incluir el apartado correspondiente al presupuesto en la memoria de solicitudes los gastos correspondientes a la inclusiÃ³n de los estudiantes en el sistema de la Seguridad Social, siempre que la empresa en cuestiÃ³n no disponga de una bolsa de trabajo destinada a tal efecto.": "Per tal de complir amb les exigÃ¨ncies del RD-L 2/2023, de 16 de marÃ§\n" +
        "(DisposiciÃ³ addicional cinquantena segona), en el cas dels cursos que\n" +
        "demanen la realitzaciÃ³ de prÃ ctiques en empreses, es deurÃ  incloure\n" +
        "lâapartat corresponent al pressupost en la memÃ²ria de solÂ·licituds les\n" +
        "despeses corresponents a la inclusiÃ³ dels estudiants en el sistema de la\n" +
        "Seguretat Social, sempre que la empresa en qÃ¼estiÃ³ no dispose dâuna\n" +
        "borsa de treball destinada a tal efecte."
}
