
import {ITeacher} from '../../../../server/src/modules/teacher/interfaces';
import {COLORS} from '../../helpers';
import Vue from 'vue';

export default Vue.extend({
    name: 'TeacherListItem',
    props: {
        draggable: {
            type: Boolean,
            default: false
        },
        teacher: {
            type: Object,
            default: null
        },
        deleteIcon: {
            type: Boolean,
            default: false
        },
        teacherIdsNoDelete: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        getInitials(teacher: ITeacher) {
            return (
                teacher.name.substring(0, 1) + teacher.surname.substring(0, 1)
            );
        },
        getColor(): string {
            const i = Math.ceil(Math.random() * COLORS.length);
            return COLORS[i];
        },
        getExternalTeacherSubtitle(teacher: ITeacher) {
            if (teacher.source_id != null) {
                const company_name = teacher.company
                    ? teacher.company.name
                    : ' - ';


                return (
                    (teacher.professional_category ||
                        teacher.position ||
                        ' - ') +
                    ' en ' +
                    (teacher.university?.name || company_name || ' - ')
                );
            } else {
                return (
                    (teacher.new_teacher_position || 'sin categorÃ­a') +
                    ' en ' +
                    (teacher.new_teacher_organization_name || 'Sin asignar')
                );
            }
        }
    }
});
