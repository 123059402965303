
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import {IMaster} from '../interfaces/master/interfaces/models/IMaster';
import {form} from './mixins';
import * as api from '@/api/index';
import NumericField from './common/NumericField.vue';
import SaveCancel from './common/SaveCancel.vue';

export default Vue.extend({
    name: 'MasterChildNew',
    components: {
        NumericField,
        SaveCancel
    },
    mixins: [form],
    data() {
        return {
            isNew: true,
            valid: false,
            errors: {
                master_parent: [],
                enrollment_price: [],
                min_students: [],
                max_students: []
            },
            rules: {
                title: [this.ruleRequired],
                master_parent: [this.ruleRequired],
                enrollment_price: [this.ruleRequired],
                min_students: [
                    // @ts-ignore
                    (v: number) => {
                        if (
                            v &&
                            this.localState.max_students &&
                            Number(v) > Number(this.localState.max_students)
                        ) {
                            return this.$i18n.t(
                                'No puede ser mayor que el nÃºmero mÃ¡ximo de estudiantes'
                            );
                        }

                        return true;
                    }
                ],
                max_students: [
                    // @ts-ignore
                    (v: number) => {
                        if (
                            v &&
                            this.localState.fees[0].min_students &&
                            Number(v) <
                                Number(this.localState.fees[0].min_students)
                        ) {
                            return this.$i18n.t(
                                'No puede ser menor que el nÃºmero mÃ­nimo de estudiantes'
                            );
                        }

                        return true;
                    }
                ]
            },
            localState: {
                title: null,
                master_parent: null,
                max_students: null,
                fees: []
            },
            masterParents: []
        };
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            getCurrentAcademicYear: 'master/getCurrentAcademicYear'
        })
    },
    mounted() {
        this.clearLocalState();
        this.getMasterParents();
    },
    methods: {
        ...mapActions('master', ['create', 'clearError']),
        async getMasterParents() {
            try {
                const response = await api.master.getAll({
                    onlyParents: true,
                    academicYearId: this.getCurrentAcademicYear.id,
                    groupParentChilds: false
                });
                // filter childs
                const masters = response.data.data.filter(
                    (m: IMaster) => m.master_parent_id == null
                );

                Vue.set(this, 'masterParents', masters);
            } catch (err) {
                throw err;
            }
        },
        clearLocalState() {
            this.localState = {
                title: null,
                master_parent: null,
                fees: [
                    {
                        enrollment_price: 0,
                        min_students: 0
                    }
                ],
                max_students: null
            };

            this.clearError();

            if (this.$refs.form != null) {
                // @ts-ignore
                this.$refs.form.resetValidation();
            }
        },
        async save() {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                // @ts-ignore
                if (this.imRoleTeacher) {
                    this.localState.teacher_responsible_id = this.currentUser.teacher.id;
                }
                // @ts-ignore
                await this.create(this.localState);
                this.$emit('created', this.localState);
                this.clearLocalState();
            }
        }
    }
});
