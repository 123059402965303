var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-tabs',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[(!_vm.tenant_sigue_connect_disabled)?_c('v-tab',[_vm._v(_vm._s(_vm.$t('SincronizaciÃ³n con Sigue')))]):_vm._e(),(!_vm.tenant_zip_files_generate_disabled)?_c('v-tab',[_vm._v(_vm._s(_vm.$t('Generar ficheros Zip')))]):_vm._e(),_c('v-tab',[_vm._v(_vm._s(_vm.$t('TÃ­tulos')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('Profesorado')))]),(
                _vm.loggedIn &&
                _vm.imRoleAdmin &&
                !_vm.tenant_platform_configure_disabled
            )?_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('ConfiguraciÃ³n Plataforma'))+" ")]):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[(!_vm.tenant_sigue_connect_disabled)?_c('v-tab-item',{key:"0"},[_c('sync-log')],1):_vm._e(),(!_vm.tenant_zip_files_generate_disabled)?_c('v-tab-item',{key:"1"},[_c('zip-files')],1):_vm._e(),_c('v-tab-item',{key:"2"},[_c('masters')],1),_c('v-tab-item',{key:"3"},[_c('teachers-tab')],1),(
                _vm.loggedIn &&
                _vm.imRoleAdmin &&
                !_vm.tenant_platform_configure_disabled
            )?_c('v-tab-item',{key:"4"},[_c('config')],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }