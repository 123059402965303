
import {mapActions, mapGetters} from 'vuex';
import Vue from 'vue';
import TeacherSubject from './TeacherSubject.vue';
import DeleteDialog from '@/components/common/DeleteDialog.vue';
import {ITeacher} from '../../../interfaces/teacher/interfaces/models/ITeacher';
import * as _ from 'lodash';

export default Vue.extend({
    name: 'SubjecTeachersList',
    components: {
        TeacherSubject,
        DeleteDialog
    },
    props: {
        subject: {
            type: Object
        },
        editStep: {
            type: Number,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            master: 'master/getData'
        }),
        stateSubject() {
            const id = this.subject.id;
            return this.$store.getters['master/getSubject'](id);
        },
        formError() {
            return this.$store.getters['master/getError'];
        },
        teachers() {
            const id = this.subject.id;
            return this.$store.getters['master/getSubject'](id).teachers;
        },
        processedHeaders() {
            return [this.processTableHeaders(this.headers)];
        },
        colorHoursAssigned() {
            return this.stateSubject.in_range_hours ? 'green' : 'red';
        },
        expandedTableHeaders() {
            const nested = !!this.headers.some(h => h.children);

            if (!nested) {
                return this.headers;
            }

            const eh: object[] = [];

            this.headers.forEach((header: object) => {
                // @ts-ignore
                if (header.children) {
                    // @ts-ignore
                    eh.push(...header.children);
                } else {
                    eh.push(header);
                }
            });

            return eh;
        },
        parentTableHeaders() {
            const nested = !!this.headers.some(h => h.children);

            if (!nested) {
                return null;
            }

            if (nested) {
                const h = this.headers.map(header => {
                    if (header.children && header.children.length > 0) {
                        return {
                            text: header.text,
                            align: header.align,
                            colspan: header.children.length
                        };
                    } else {
                        return {
                            text: '',
                            align: header.align,
                            colspan: 1
                        };
                    }
                });

                return h;
            }

            return null;
        }
    },
    data() {
        return {
            trIndex: 2,
            openTeacherUI: false,
            isNewTeacher: false,
            deleteTeacherUI: false,
            currentTeacher: {},
            search: '',
            headers: [
                {
                    text: '',
                    align: 'center',
                    //width: 20,
                    sortable: false,
                    value: 'actions'
                },
                {
                    text: this.$i18n.t('Profesor'),
                    align: 'start',
                    sortable: true,
                    value: 'name'
                },
                {
                    text: this.$i18n.t('Origen'),
                    align: 'start',
                    sortable: true,
                    value: 'teacher_type_code'
                },
                {
                    text: this.$i18n.t('Responsable'),
                    align: 'center',
                    sortable: true,
                    value: 'responsible'
                },
                {
                    text: this.$i18n.t('Docencia teÃ³rica'),
                    value: 'theoretical_teaching',
                    sortable: true,
                    align: 'center',
                    children: [
                        {
                            text: this.$i18n.t('Horas'),
                            value: 'TeacherSubject.theoretical_teaching_hours',
                            sortable: true,
                            align: 'center'
                        },
                        {
                            text: this.$i18n.t('â¬/hora'),
                            value: 'TeacherSubject.theoretical_teaching_price',
                            sortable: true,
                            align: 'center'
                        }
                    ]
                },
                {
                    text: this.$i18n.t('Docencia prÃ¡ctica'),
                    value: 'TeacherSubject.practical_teaching_hours',
                    sortable: true,
                    align: 'center',
                    children: [
                        {
                            text: this.$i18n.t('Horas'),
                            value: 'TeacherSubject.practical_teaching_hours',
                            align: 'center',
                            sortable: true
                        },
                        {
                            text: this.$i18n.t('â¬/hora'),
                            value: 'TeacherSubject.practical_teaching_price',
                            align: 'center',
                            sortable: true
                        }
                    ]
                },
                {
                    text: this.$i18n.t('TutorÃ­as obligatorias'),
                    value: 'TeacherSubject.tutorial_teaching_hours',
                    sortable: true,
                    align: 'center',
                    children: [
                        {
                            text: this.$i18n.t('Horas'),
                            value: 'TeacherSubject.tutorial_teaching_hours',
                            align: 'center',
                            sortable: true
                        },
                        {
                            text: this.$i18n.t('â¬/hora'),
                            value: 'TeacherSubject.tutorial_teaching_price',
                            align: 'center',
                            sortable: true
                        }
                    ]
                }
            ]
        };
    },
    methods: {
        ...mapActions('master', [
            'deleteTeacherSubject',
            'clearError',
            'clearNewTeacher'
        ]),
        async successCreated() {
            this.openTeacherUI = false;
            await this.clearNewTeacher();
        },
        async editTeacherSubject(teacher: ITeacher) {
            this.currentTeacher = teacher; //_.cloneDeep(teacher);

            this.openTeacherUI = true;
            // call action
            await this.clearNewTeacher();
            this.isNewTeacher = false;
        },
        newTeacher() {
            this.currentTeacher = {};

            this.openTeacherUI = true;
            // call action
            this.clearNewTeacher();
            this.isNewTeacher = true;
        },
        deleteTeacherDialog(teacher: ITeacher) {
            this.currentTeacher = _.cloneDeep(teacher);

            this.deleteTeacherUI = true;
        },
        cancelDeleteTeacher() {
            this.currentTeacher = {};

            this.deleteTeacherUI = false;
        },
        async deleteTeacher() {
            try {
                this.deleteTeacherUI = false;
                // @ts-ignore
                await this.deleteTeacherSubject({
                    masterId: this.master.id,
                    teacherSubject: this.currentTeacher.TeacherSubject
                });

                this.currentTeacher = {};
            } catch (err) {
                // avoid uncaught promise
            }
        },
        tableHeaderIndex(i: number = 0) {
            return Math.random() + i;
        },
        classForInactive(active: boolean) {
          return active == false ? 'deleted' : '';
        }
    }
});
