<template>
    <div>
        <div class="master-detail-caption">{{ detailInfo }}</div>
        <DxDataGrid
            key-expr="id"
            :data-source="dataSource"
            :show-borders="true"
            :column-auto-width="true"
            :focused-row-enabled="true"
            :auto-navigate-to-focused-row="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            data-row-template="dataRowTemplate"
            @row-dbl-click="goToMaster"
        >
            <DxColumn :caption="$i18n.t('TÃ­tulo')" data-field="master_title" />
            <DxColumn
                :caption="$i18n.t('Asignatura')"
                data-field="subject_name"
            />
            <DxColumn
                :caption="$i18n.t('Docencia TeÃ³rica')"
                data-field="theoretical_teaching_hours"
            />
            <DxColumn
                :caption="$i18n.t('Docencia PrÃ¡ctica')"
                data-field="practical_teaching_hours"
            />
            <DxColumn
                :caption="$i18n.t('TutorÃ­as obligatorias')"
                data-field="tutorial_teaching_hours"
            />
            <template #dataRowTemplate="{ data: rowInfo }">
                <tr class="main-row">
                    <td :class="getChildClass(rowInfo.data)">
                        {{ rowInfo.data.master_title }}
                    </td>
                    <td :class="getChildClass(rowInfo.data)">
                        {{ rowInfo.data.subject_name }}
                    </td>
                    <td :class="getChildClass(rowInfo.data)">
                        {{ rowInfo.data.theoretical_teaching_hours }}
                    </td>
                    <td :class="getChildClass(rowInfo.data)">
                        {{ rowInfo.data.practical_teaching_hours }}
                    </td>
                    <td :class="getChildClass(rowInfo.data)">
                        {{ rowInfo.data.tutorial_teaching_hours }}
                    </td>
                </tr>
            </template>
        </DxDataGrid>
    </div>
</template>
<script>
import {DxColumn, DxDataGrid} from 'devextreme-vue/data-grid';

import DataSource from 'devextreme/data/data_source';
import {createStore} from 'devextreme-aspnet-data-nojquery';
import Vue from 'vue';

export default Vue.extend({
    components: { DxDataGrid, DxColumn },
    props: {
        templateData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            dataSource: this.getSubjects(),
            detailInfo: this.$i18n.t(
                'Detalle de asignaturas(en rojo no suman al estar vinculadas a otro tÃ­tulo)'
            ),
        };
    },
    methods: {
        goToMaster(e) {
            if (e.rowType === 'data') {
                const masterId = e.data.master_id;
                const routeData = this.$router.resolve({
                    name: 'Master',
                    params: { masterId: `${masterId}` },
                });
                window.open(routeData.href, '_blank');
            }
        },
        getChildClass(rowInfoData) {
            if (rowInfoData.master_parent_id != null) {
                return 'text-decoration-line-through red--text text--lighten-1';
            } else {
                return '';
            }
        },
        getSubjects() {
            console.log(this.templateData.data);
            return new DataSource({
                store: createStore({
                    key: 'id',
                    loadUrl: `${process.env.VUE_APP_API_URL}teachers/${this.templateData.data.teacher_id}/subjects?year=${this.templateData.data.academic_year}&by-person=false`,
                }),
            });
        },
    },
});
</script>
<style>
.master-detail-caption {
    padding: 0 0 5px 10px;
    font-size: 14px;
    font-weight: bold;
}
</style>
