/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 *   Unless required by applicable law or agreed to in writing, software
 *   distributed under the License is distributed on an "AS IS" BASIS,
 *   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *   See the License for the specific language governing permissions and
 *   limitations under the License.
 */
export default {
    error: {
        msg_error: "S'ha produÃ¯t un error",
        msg_description: 'Contacta amb el suport o obri una incidÃ¨ncia'
    },
    'MenÃº Inicio': 'Home Menu',
    'Iniciar sesiÃ³n': 'Log in',
    'Cerrar sesiÃ³n': 'Log out',
    'Manual de usuario': 'User manual',
    'TÃ­tulos Propios UV': 'UV-specific degrees',
    Menu: 'Menu',
    Borrar: 'Delete',
    Confirmar: 'Confirm',
    "EstÃ s segur/a d'eliminar la referÃ¨ncia al tÃ­tol vinculat(pare)?": 'Are you sure to remove the reference to the linked degree(parent)?',
    'EstÃ¡s seguro/a?': 'Are you sure?',
    Cancel: 'Cancel',
    Aceptar: 'OK',
    'El item serÃ¡ borrado permanentemente. Quieres continuar':
        'The item will be deleted permanently. Do you want to continue?',
    'Nueva tasa': 'New fee',
    'Modificar tasa': 'Change fee',
    'Ha ocurrido un error en el servidor': 'A server error occurred',
    'Grupo o pÃºblico al que va dirigida la tasa':
        'Group that will be charged the fee',
    'Importe de la matrÃ­cula': 'Enrolment fee',
    'MÃ­nimo de estudiantes': 'Minimum number of students',
    Guardar: 'Save',
    'No puede ser mayor que el nÃºmero mÃ¡ximo de estudiantes del tÃ­tulo':
        'The number cannot exceed the maximum number of students in the degree',
    'Tasas del tÃ­tulo': 'Fees of the degree',
    'Guarda los cambios para poder modificar las tasas del tÃ­tulo':
        'Save changes to modify the fees of the degree',
    'Eliminar tasa': 'Remove fee',
    'Se eliminarÃ¡ la tasa completa, lo que afectarÃ¡ al presupuesto.':
        'The full fee will be removed, which will affect the budget',
    PÃºblico: 'Public',
    'MÃ­n. Estudiantes': 'Min. number of students',
    'MatrÃ­cula ': 'Enrolment ',
    'Total ': 'Total',
    'Elimina el fichero': 'Delete the file',
    'Descarga el fichero': 'Download the file',
    'Haz clik para adjuntar un nuevo fichero': 'Click to attach a new file',
    Salir: 'Quit',
    'Cancelar cambios': 'Cancel changes',
    'Escribe para buscar una organizaciÃ³n': 'Type in to find an organisation',
    'El dato es requerido': 'The data is required',
    'Se ha producido un error consultando el servidor':
        'An error occurred while querying the server',
    nuevo: 'new',
    'Escribe para buscar por nombre, apellidos o NIF':
        'Type in to search by name, surnames or NIF',
    'Eliminar ': 'Delete',
    'Crea tÃ­tulos propios': 'Create UV-specific degrees',
    'Crear TÃ­tulo': 'Create degree',
    'Crear TÃ­tulo Vinculado': 'Create associated degree',
    'Gestiona TÃ­tulos Propios': 'Manage UV-specific degrees',
    'Revisar tÃ­tulos propios presentados':
        'Review / Modify UV-specific degrees submitted',
    'Inicia sesiÃ³n': 'Log in',
    'Error al iniciar sesiÃ³n': 'Error logging in',
    'Nombre de usuario UV o Correo electrÃ³nico UV':
        'UV username or UV e-mail address',
    'Puedes introducir tu nombre de usuario uv o el correo electrÃ³nico':
        'You can enter your UV username or e-mail address',
    ContraseÃ±a: 'Password',
    'ContraseÃ±a requerida': 'Password required',
    'E-mail requerido': 'E-mail address required',
    'Informe del Ã³rgano responsable (departament, instituto, ...)':
        'Report of the body responsible (department, institute, etc.)',
    'Curriculum de profesorado externo': 'CV of external teaching staff',
    'En un Ãºnico fichero zip si son varios':
        'In a single zip file, if there are several',
    'Otra documentaciÃ³n relevante': 'Other relevant documents',
    'Observaciones adicionales': 'Other remarks',
    'Â¡El fichero no puede exceder de 10MB!': 'The file must not exceed 10MB',
    'Ãrgano proponente del curso': 'Body proposing the course',
    'Tipo de Ã³rgano': 'Type of body',
    Ãrgano: 'Body',
    Calendario: 'Schedule',
    EdiciÃ³n: 'Edition',
    'MÃ¡ximo de estudiantes': 'Maximum number of students',
    'Inicio / Fin de las clases': 'Start/end of lessons',
    'Campo autocalculado': 'Autocalculated field',
    'Fecha Fin PreinscripciÃ³n': 'Pre-enrolment end date',
    'MÃ­mino 15 dÃ­as antes del inicio del curso':
        'At least 15 days before the start of the course',
    'Entidades colaboradoras': 'Collaborating entities',
    'LocalizaciÃ³n - Horarios': 'Location - Schedules',
    'Recuerda que debes crear el plan de estudios con el mismo idioma elegido para el tÃ­tulo propio.':
        'Remember to create the curriculum in the same language chosen for the UV-specific degree',
    'Modalidad de imparticiÃ³n': 'Mode of delivery',
    Idioma: 'Language',
    'Lugar de imparticiÃ³n': 'Place of teaching',
    'DÃ­as de imparticiÃ³n y horarios': 'Days and times of lessons',
    'El tipo de Ã³rgano es requerido': 'Type of body is required',
    'El Ã³rgano es requerido': 'Body is required',
    'La fecha es requerida': 'Date is required',
    'No puede ser menor que el nÃºmero mÃ­nimo de estudiantes':
        'The number cannot be less than the minimum number of students',
    'El presupuesto del tÃ­tulo vinculado se gestiona desde el tÃ­tulo "padre"':
        'The budget of the associated degree is managed from the "parent"',
    'Total Prespuesto': 'Total budget',
    'Total Ingresos': 'Total revenue',
    'Total Gastos': 'Total expenditure',
    Superavit: 'Surplus',
    DÃ©ficit: 'Deficit',
    'Desglose Ingresos': 'Revenue breakdown',
    'NÃºmero mÃ­nimo de estudiantes': 'Minimum number of students',
    Patrocinios: 'Sponsorships',
    'Ingresos por hijos': 'Income from children',
    'Salarios DirecciÃ³n ': "Directors' salaries",
    Salario: 'Salary',
    'Desglose de gastos': 'Expenditure breakdown',
    'Canon universitario': 'University fee',
    'Porcentaje GestiÃ³n Universitaria': 'Percentage for university management',
    'Puedes especificar uno distinto del general del':
        'You may specify one different from the general',
    'GestiÃ³n universitaria': 'University management',
    'Fondo de becas': 'Scholarship fund',
    Profesorado: 'Teaching staff',
    'DirecciÃ³n (hast ': 'Director (until ',
    'Material docente': 'Class materials',
    'Viajes y dietas': 'Travel and subsistence allowance',
    Otros: 'Others',
    Seguros: 'Insurance',
    'Publicidad y promociÃ³n': 'Advertising and promotion',
    'Actividades complementarias': 'Complementary activities',
    'Medios e instalaciones': 'Means and facilities',
    TÃ­tulo: 'Degree',
    'MÃ¡ximo ${this.constants.OTHER_AMOUNT_MAX_PERCENT}% del total de ingresos':
        'Maximum ${this.constants.OTHER_AMOUNT_MAX_PERCENT}% of total revenue',
    'La suma de salarios excede el mÃ¡ximo permitido':
        'The sum of salaries exceeds the maximum allowed',
    Asociar: 'Associate',
    'al tÃ­tulo propio': 'to the UV-specific degree',
    'AÃ±adir Director o Directora al tÃ­tulo': 'Add degree director',
    'Escribe nombre, apellidos o NIF para elegir un Director UV':
        'Type in name, surnames or NIF to choose a UV director',
    'Escribe nombre, apellidos o NIF para elegir un Director o Directora Externo/a':
        'Type in name, surnames or NIF to choose an external director',
    'Director/Directora UV': 'UV director',
    'Director/Directora Externo': 'External director',
    'Curso acadÃ©mico para el tÃ­tulo': 'Academic year for the degree',
    'DuraciÃ³n en cursos acadÃ©micos del tÃ­tulo':
        'Duration of the degree in academic years',
    'No se recomienda superar los 60 ECTS por curso acadÃ©mico':
        'Exceeding 60 ECTS per academic year is not recommended',
    'TÃ­tulo vinculado': 'Associated degree',
    'Nombre para el tÃ­tulo propio': 'Name for the UV-specific degree',
    'Profesor/a UV solicitante del tÃ­tulo propio (UV)':
        'UV lecturer proposing the UV-specific degree',
    'Directores o Directoras Universitat de ValÃ¨ncia':
        'Directors from the Universitat de ValÃ¨ncia',
    'AÃ±adir director o directora': 'Add director',
    'Directores o Directoras Externos/as (si los hubiera)': 'External directors (if any)',
    'AÃ±adir director o directora externo/a': 'Add external director',
    'Se borrarÃ¡ al profesor de la asignatura':
        'The lecturer of the subject will be deleted',
    'El aÃ±o acadÃ©mico es requerido': 'Academic year is required',
    'La duraciÃ³n es requerida': 'Duration is required',
    'El tÃ­tulo es requerido': 'Degree is required',
    'El director responsable de la uv es requerido':
        'UV director in charge is required',
    '${this.masterState.master_state.name':
        '${this.masterState.master_state.name',
    'Detalle resumen profesorado uv': 'Detailed summary of UV teaching staff',
    'Detalle resumen profesorado externo':
        'Detailed summary of external teaching staff',
    DirecciÃ³n: 'Director',
    'Datos bÃ¡sicos': 'Basic data',
    'Otros datos': 'Other details',
    'Plan de estudios': 'Curriculum',
    Claustro: 'Senate',
    Presupuesto: 'Budget',
    Anexos: 'Annexes',
    Presentar: 'Submit',
    'El tÃ­tulo propio no existe': 'The UV-specific degree does not exist',
    'OcurriÃ³ un error ': 'An error occurred',
    'Listado de tÃ­tulos presentados': 'List of degrees submitted',
    Buscar: 'Search',
    'Se produjo un error realizando la operaciÃ³n.':
        'An error occurred during the operation',
    'Presenta el tÃ­tulo para su revisiÃ³n por los tÃ©cnicos':
        'Submit the degree for review by specialists',
    'Al ser aprobado, no se permiten modificaciones': 'Upon approval, no modifications are allowed',
    'Marcar el tÃ­tulo como inactivo.': 'Mark degree as inactive,',
    'Marcar como Revisado': 'Mark as reviewed',
    'Deja el tÃ­tulo como "correcto" y no permite su posterior ediciÃ³n por parte del director':
        'The degree is marked as "correct" and further editing by the director is not allowed',
    'Pedir subsanaciÃ³n': 'Request amendment',
    'Pide al director que realice modificaciones sobre el tÃ­tulo.':
        'Ask the director to make changes to the degree',
    'Alta en Sigue': 'Register in SIGUE',
    Memoria: 'Report',
    'La bÃºsqueda de ': 'The search for ',
    ' no produjo ningÃºn resultado': 'did not yield any results',
    'Alta de tÃ­tulo en SIGUE': 'Register degree in SIGUE',
    'Vas a traspasar el tÃ­tulo al sistema SIGUE. Una vez traspasado el tÃ­tulo no se podrÃ¡ modificar. Quieres continuar?':
        'You are going to transfer the degree to the SIGUE system. Once the degree has been transferred, it cannot be modified. Are you sure?',
    Si: 'Yes',
    Cancelar: 'Cancel',
    'Presentar TÃ­tulo': 'Submit degree',
    'Archivar TÃ­tulo': 'Archive degree',
    'Aprobar Ã­tulo': 'Approve degree',
    'Al presentarlo, el tÃ­tulo serÃ¡ revisado por los tÃ©cnicos y dejarÃ¡ de ser editable para el director. Quieres continuar?':
        'On submission, the degree will be reviewed by the specialists and it will no longer be editable for the director. Are you sure?',
    'Marcar tÃ­tulo como revisado': 'Mark the degree as reviewed',
    'Marcar tÃ­tulo como borrador.': 'Mark the degree as draft.',
    'Marcar como Borrador': 'Mark as draft',
    'Al marcar un tÃ­tulo como revisado, se dara como correcta la informaciÃ³n asociada pudiendo presentar el tÃ­tulo.  Quieres continuar?':
        'By marking a degree as reviewed, any associated information will be considered correct and the degree can then be submitted.  Are you sure?',
    'Pedir al director subsanar errores': 'Ask the director to amend errors',
    'Al pedir subsanaciÃ³n, el tÃ­tulo pasarÃ¡ de nuevo a estado borrador y el director deberÃ¡ volver a presentarlo. Quieres continuar?':
        'By requesting an amendment, the degree will be reverted to draft status and the director will have to resubmit it. Are you sure?',
    'Al marcar un tÃ­tulo como borrador, este volverÃ¡ a ser editable por el usuario y deberÃ¡ pasar por el flujo normal de aprobaciÃ³n. Quieres continuar?':
        'By mark a degree as draft, it will return to its original state, and it has to pass the normal approval flow. Are you sure?',
    'TÃ­tulo padre': 'Parent degree',
    Director: 'Director',
    Centro: 'Faculty or school',
    ECTS: 'ECTS',
    Tipo: 'Type',
    'F. Fin PreinscripciÃ³n': 'Pre-enrolment deadline',
    Estado: 'Status',
    'En Sigue': 'In SIGUE',
    'Relevancia acadÃ©mica': 'Academic relevance',
    'Objetivos del curso': 'Aims of the course',
    'Requisitos de acceso y perfil de ingreso': 'Access requirements and entry profile',
    'Salidas profesionales y/o perfil de egreso': 'Career opportunities and/or graduation profile',
    'MetodologÃ­a general': 'Overall Methodology',
    'Ãmbitos de conocimiento': 'Areas of knowledge',
    'InformaciÃ³n para publicidad': 'Information for advertising',
    'InformaciÃ³n para publicidad: esta informaciÃ³n aparecerÃ¡ en la web': 'Information for advertising, this information will appear at the web',
    'Rama de conocimiento': 'Branch of knowledge',
    'Escribe aquÃ­ el interÃ©s y la relevancia acadÃ©mica cientÃ­fica y profesional del curso':
        'Write here the interest and academic, scientific and professional relevance of the course',
    'Escribe aquÃ­ los objetivos del curso': 'Write here the aims of the course',
    'Escribe aquÃ­ el perfil de estudiantes y requisitos de acceso':
        'Write here the student profile and entry requirements',
    'Escribe aquÃ­ las salidas profesionales del curso':
        'Write here the career opportunities of the course',
    'Escribe aquÃ­ la metodologÃ­a general del curso':
        'Write here the overall methodology of the course',
    'No se puede presentar el tÃ­tulo.  AlgÃºn paso no ha sido completado correctamente.':
        'The degree cannot be submitted.  Some step has not been completed correctly',
    'Presentar el tÃ­tulo': 'Submit the degree',
    'El tÃ­tulo estÃ¡ ': 'The degree is ',
    'Detalle - Asignaturas': 'Detail - Subjects',
    'Resumen de horas impartidas y ECTS': 'Summary of hours taught and ECTS',
    'Actualiza la lista de asignaturas del tÃ­tulo vinculado':
        'Update the list of subjects of the associated degree',
    'Actualizar asignaturas': 'Update subjects',
    Nombre: 'Name',
    'Tipo docencia': 'Type of teaching',
    PrÃ¡cticas: 'Internships',
    'Crear asignatura': 'Create subject',
    'Modificar asignatura': 'Modify subject',
    'Nombre de la asignatura': 'Name of the subject',
    'AÃ±o del tÃ­tulo en el que se imparte la asignatura':
        'Year of the degree in which the subject is taught',
    'Tipo de docencia': 'Type of teaching',
    'Es trabajo fÃ­n de master': "Master's thesis",
    'El master ya tiene una asignatura fin de master.':
        "The master's degree already has a master's final subject",
    'Es asignatura de pÅacticas (curriculares)': '(Curricular) internships',
    'El nombre es requerido': 'Name is required',
    'El tipo de docencia es requerido': 'Type of teaching is required',
    'El nÃºmero de ECTS es requerido': 'Number of ECTS is required',
    'El nÃºmero mÃ­mino de ECTS es ': 'The minimum number of ECTS is',
    'El nÃºmero mÃ­mino de ECTS para el TFM es':
        "The minimum number of ECTS for the master's thesis is ",
    'SÃ³lo se admite medio ECTS': 'Only half an ECTS is allowed',
    'El campo es requerido': 'The field is required',
    'Detalle plan de estudios': 'Detailed curriculum',
    'AÃ±adir nueva asignatura': 'Add new subject',
    'Actualiza asignaturas del tÃ­tulo vinculado':
        'Update subjects of the associated degree',
    'Eliminar asignatura': 'Delete subject',
    'Eliminar tÃ­tulo': 'Delete degree',
    'GuÃ­a docente': 'Course guide',
    Profesores: 'Teaching staff',
    'Total AÃ±o ': 'Total year',
    Total: 'Total',
    'Se eliminarÃ¡ la asignatura completa, con toda la informaciÃ³n de profesores y plan de estudios.':
        'The full subject, including teaching staff and curriculum information, will be deleted',
    AÃ±o: 'Year',
    'Horas min.': 'Min. Hours',
    'Horas max': 'Max. Hours',
    'Horas asignadas': 'Hours assigned',
    'Detalle profesorado asignatura: ':
        'Details of teaching staff of the subject: ',
    'Volver a la lista de asignaturas': 'Back to the list of subjects',
    'Asociar profesor': 'Link lecturer',
    'Hs. MÃ­minas': 'Min. Hours',
    'Hs. MÃ¡ximas': 'Max. Hours',
    'Total Asignadas:': 'Total assigned:',
    'Modificar datos': 'Modify data',
    'Eliminar asociaciÃ³n con profesor': 'Remove link with lecturer',
    Profesor: 'Lecturer',
    Origen: 'Origin',
    Responsable: 'Person in charge',
    'Docencia teÃ³rica': 'Theory teaching',
    Horas: 'Hours',
    'â¬/hora': 'â¬/hour',
    'Docencia prÃ¡ctica': 'Practical teaching',
    'TutorÃ­as obligatorias': 'Compulsory tutorials',
    'GuÃ­a docente de la Asignatura:': 'Course guide for the subject',
    'Resumen de la asignatura': 'Summary of the subject',
    'Resultado del aprendizaje de la asignatura':
        'Learning outcome for the subject',
    'MetodologÃ­a del aprendizaje': 'Learning methodology',
    'Sistemas de evaluaciÃ³n de la asignatura':
        'Systems of assessment of the subject',
    'BibliografÃ­a de la asignatura': 'Bibliography of the course',
    'Descriptores del temario de la asignatura':
        'Descriptors of the course syllabus',
    'Inicio de la docencia': 'Start of teaching',
    'FinalizaciÃ³n de la docencia': 'End of teaching',
    'Escribe aquÃ­ el resumen de la asignatura':
        'Write here a summary of the subject',
    'Escribe aquÃ­ el resultado del aprendizaje':
        'Write here the learning outcome',
    'Escribe aquÃ­ la metodologÃ­a de aprendizaje de la asignatura':
        'Write here the learning methodology of the subject',
    'Escribe aquÃ­ los sistemas de evaluaciÃ³n de la asignatura':
        'Write here the systems of assessment of the subject',
    'Escribe aquÃ­ la biblografÃ­a de la asignatura':
        'Write here the biblography of the course',
    'Escribe aquÃ­ los descriptores del temario de la asignatura':
        'Write here the descriptors of the course syllabus',
    'El temario no puede estar vacÃ­o.': 'The syllabus cannot be left empty',
    'El tÃ­tulo es un ': 'The degree is a ',
    'El tÃ­tulo no tiene asignado ningÃºn tipo de titulaciÃ³n':
        'The degree has not been assigned any type of qualification',
    ' AÃ±o ': 'Year',
    'Nuevo Profesor': 'New lecturer',
    'Modificar profesor': 'Change lecturer',
    Apellidos: 'Surnames',
    NIF: 'NIF',
    'NIF sin guiones, sÃ³lo nÃºmeros y letra':
        'NIF without the dash, only numbers and letter',
    'E-mail': 'E-mail',
    'Fecha de nacimiento': 'Date of birth',
    TelÃ©fono: 'Phone number',
    'Es un profesor de otra universidad distinta a la UV':
        'Lecturer from a university other than the UV',
    'Es un profesor que trabaja en una empresa':
        'Lecturer working in a company',
    'Es persona fÃ­sica': 'Is a natural person',
    'Nuevo Profesor UV': 'New UV lecturer',
    'Modificar profesor UV': 'Change UV lecturer',
    Departamento: 'Department',
    'CategorÃ­a profesional': 'Professional category',
    'Ãrea de conocimiento': 'Area of knowledge',
    'Asociar Profesor a asignatura': 'Link lecturer to subject',
    'Modificar RelaciÃ³n Profesor - Asignatura': 'Modify link lecturer-subject',
    'Nuevo Profesor Externo': 'New external lecturer',
    'Modificar datos profesor externo': 'Change details of external lecturer',
    'Nuevo P.D.I/P.A.S UV': 'New UV PDI/PAS',
    'Modificar datos P.D.I/P.A.S UV': 'Change details of UV PDI/PAS',
    'Elige un profesor UV': 'Choose a UV lecturer',
    'Elige un profesor externo a la UV':
        'Choose a lecturer from outside the UV',
    'Es responsable': 'Lecturer in charge',
    'Todas las asignaturas tienen un profesor responsable que es la persona que firmarÃ¡ el acta. Caso de no ser profesor uv, el acta la firmarÃ¡ el director':
        'All subjects have a lecturer in charge who is responsible for signing the exam records. If he/she is not a UV lecturer, the director will sign the records',
    'NÃºmero de horas': 'Number of hours',
    'â¬ / hora': 'â¬/hour',
    'El profesor ya estÃ¡ asociado a la asignatura':
        'The lecturer is already linked to the subject',
    'SÃ³lo se admiten medias horas': 'Only half hours are allowed',
    'SÃ³lo se admiten mitades (0,5)': 'Only halves are allowed (0.5)',
    'Superado el mÃ¡ximo permitido de': 'Exceeding the maximum number of ',
    'Coste Total profesorado': 'Total cost of teaching staff',
    'Horas Docenci': 'Hours of teaching',
    '% horas docencia profesorado ': '% hours of teaching of lecturers',
    'Coste Total': 'Total cost',
    'Crear tÃ­tulo propio vinculado': 'Create associated UV-specific degree',
    "Elige el tÃ­tulo propio 'padre'": "Choose the 'parent' UV-specific degree",
    "Nombre para el nuevo tÃ­tulo propio vinculado ('hijo')":
        "Name for the new associated UV-specific degree ('subsidiary')",
    'El nombre debe ir precedido del tipo de tÃ­tulo (Master de FormaciÃ³n Permanente en..., Experto universitario en...). Ejemplos:':
        "The name must be preceded by the type of degree (LongLife Learning Master in..., University Expert in...). Examples:",
    'El nombre debe ir precedido del tipo de tÃ­tulo (Microcredencial en..., Certificado Universitario en...). Ejemplos:':
        "The name must be preceded by the type of degree (Microcredential in..., University Expert in...). Examples:",
    'Diploma de EspecializaciÃ³n': 'Diploma of specialisation',
    'Derecho de la economÃ­a digital': 'Law in the digital economy',
    'Certificado Universitario ': 'Continuous Learning Certificate',
    'Documentos digitales y archivos': 'Digital documents and archives',
    'Experto Universitario': 'University Expert',
    'en "Startup Management"': 'in"Startup Management"',
    'No puede ser mayor que el nÃºmero mÃ¡ximo de estudiantes':
        'The number cannot exceed the maximum number of students',
    'Crear tÃ­tulo propio': 'Create UV-specific degree',
    'Crear tÃ­tulo - FormaciÃ³n ContÃ­nua': 'Create Degree - Continuous Learning',
    'Crear TÃ­tulo de FormaciÃ³n ContÃ­nua': 'Create Degree of Continuous Learning',
    'Curso acadÃ©mico de inicio para el tÃ­tulo':
        'Starting academic year for the degree',
    'Master de formaciÃ³n permanente en ': "LongLife Learning Master in ",
    "Microcredential ": "Microcredential",
    'Terapia Manual: Enfoque- Neuro-OrtopÃ©dico ClÃ­nico':
        'Manual Therapy: Clinical Neuro-Orthopaedic Approach',
    'Fichero vacÃ­o': 'Empty file',
    'master unknown': "unknown master's degree",
    'Asignaturas obligatorias': 'Compulsory subjects',
    'PrÃ¡cticas Externas': 'External internships',
    'Trabajo Final de MÃ¡ster (TFM)': "Master's Thesis (TFM)",
    EspaÃ±ol: 'Spanish',
    ValenciÃ : 'Catalan',
    Draft: 'Draft',
    Reviewed: 'Reviewed',
    Submitted: 'Submitted',
    Approved: 'Approved',
    Archived: 'Archived',
    'Published with errors': 'Published with errors',
    Amendment: 'Amendment',
    Amended: 'Amended',
    "Master": 'Lifelong Learning Master',
    'University Expert': 'University Expert',
    'Diploma of Specialisation': "Diploma of Specialisation",
    'University Certificate': 'Continuous Learning Certificate',
    'Faculty or school': 'Faculty or school',
    Department: 'Departament',
    'Research Institute': "Research Institute",
    'Arts and Humanities': 'Arts and Humanities',
    Sciences: 'Sciences',
    'Health Sciences': 'Health Sciences',
    'Social and Legal Sciences': 'Social and Legal Sciences',
    'Engineering and Architecture': 'Engineering and Architecture',
    'Business Management': 'Business Management',
    'Social and Legal': 'Social and Legal',
    Humanities: 'Humanities',
    'Education Sciences': 'Education Sciences',
    Psychology: 'Psychology',
    'Environment, Health and Safety': 'Environment, Health and Safety',
    Health: 'Health',
    'Science and Technology': 'Science and Technology',
    'Physical Activity and Sport': 'Physical Activity and Sport',
    Spanish: 'Spanish',
    English: 'English',
    Online: 'Online',
    'Face-to-face': 'Presencial',
    Blended: 'Blended',
    'CÃ³digo UV del tÃ­tulo': 'UV code for the degree',
    'Otro Ã³rgano proponente del curso (OPCIONAL)':
        'Another body proposing the course (OPTIONAL)',
    'Dado que se trata de un profesor UV no funcionario, contemplar en los honorarios del profesor el pago destinado a la seguridad social.':
        "Given that the lecturer is not a UV public employee, include the social security costs in the lecturer's fees",
    'Un curso acadÃ©mico': 'One academic year',
    'Dos cursos acadÃ©micos': 'Two academic years',
    'Tres cursos acadÃ©micos': 'Three academic years',
    'Un fichero pdf': 'One pdf file',
    'Curso 2021/2022': 'Year 2021/2022',
    'Acuerdo para realizaciÃ³n de tÃ­tulos propios (profesorado UV)':
        'Agreement for teaching UV-specific degrees (UV teaching staff)',
    'No se puede presentar el tÃ­tulo. AlgÃºn paso no ha sido completado correctamente.':
        'The degree cannot be submitted. Some step has not been completed correctly',
    ' Modificar / Revisar tÃ­tulo': 'Modify / Review degree',
    'Se eliminarÃ¡ el tÃ­tulo con todos sus datos. EstÃ¡s seguro/a?':
        'The degree and all the data will be deleted. Are you sure?',
    'El plazo de presentaciÃ³n ha finalizado.':
        'The submission period has ended',
    'Agrupar padres e hijos': 'Group parent and subsidiary',
    'El tipo de fichero es obligado': 'File type is required',
    'Modelo 83': 'Model 83',
    'CurrÃ­culums profesores externos': 'External teachers curriculums',
    'Puedes obtener un fichero zip con todos los ficheros del modelo 83 de todos los tÃ­tulos de un curso acadÃ©mico pinchando en "Generar Fichero"':
        'You can get a zip file with all model 83\'s files of all academic year masters clicking in "Generate File"',
    'Filtrar por curso acadÃ©mico': 'Filter by academic courses',
    'Importar tÃ­tulo de otro curso acadÃ©mico':
        'Import degree from another academic year',
    'Curso acadÃ©mico': 'Academic year',
    "Selecciona un tÃ­tulo de la lista y pincha en 'Crear tÃ­tulo'":
        "Selects one degree from the list below and click 'Create degree'",
    'TÃ­tulo importado con Ã©xito': 'Degree imported successfully',
    'Se crearÃ¡ un nuevo tÃ­tulo idÃ©ntico al seleccionado para el curso acadÃ©mico actual. Quieres continuar?': "A new degree will be created identical to the one selected for the current academic year. You're sure",
    'RevisiÃ³n de cambios': 'Change review',
    'Orden': 'Order',
    'Requerido': 'Required',
    'Abajo': 'Down',
    'Arriba': 'Up',
    'Pincha y arrastra la fila para ordenar': 'Click and drag the row to set order',
    'Precio general': 'General price',
    'Escribe una contraseÃ±a para tu cuenta': 'Write a password for your new account',
    'Error estableciendo la contraseÃ±a': 'Error setting password',
    'Vuelve a introducir la contraseÃ±a': 'Write password again',
    "Establecer contraseÃ±a": "Set password",
    'La contraseÃ±a se ha establecido correctamente. Ya puedes iniciar sesiÃ³n en la aplicaciÃ³n Solicitudes para gestionar tus tÃ­tulos.': 'Password successfully setted. You can login in the application to manage your degrees.',
    'Solicitud de cambio de contraseÃ±a': 'Password change request',
    'Esta opciÃ³n sÃ³lo es vÃ¡lida para usuarios externos a UV.': 'This option is valid only for external users (no UV).',
    'Error al solicitar cambio de contraseÃ±a': 'Error while requesting password change',
    'Correo electrÃ³nico de acceso de la aplicaciÃ³n': 'Application access email',
    'Enviar solicitud de cambio de contraseÃ±a': 'Send password change request',
    'He olvidado mi contraseÃ±a': 'I\'ve forgotten my password',
    'Las contraseÃ±as deben cumplir los siguientes requisitos:': 'Passwords must meet the following requirements:',
    'Debe tener al menos 6 caracteres': 'Must be at least 6 characters',
    'Debe contener una mayÃºscula': 'Must contain a capital letter',
    'Debe contener un nÃºmero': 'Must contain a number',
    'Debe contener un carÃ¡cter especial [!@#$%*]': 'It must contain a special character [! @ # $% *]',
    'Correo electrÃ³nico':'Email address',
    'Se ha enviado un correo a tu cuenta con el enlace para establecer tu contraseÃ±a': 'An email has sent to you with the link to set password',
    'SincronizaciÃ³n con Sigue': 'Sigue Synchronization',
    'TEACHER_OTHER_UNIV': 'Other Universities Teachers',
    'TEACHER_EXTERNAL': 'External Teachers',
    'CENTER': 'Centers',
    'TEACHER_UV': 'UV Teachers',
    'COMPANY': 'Companies',
    'DEPARTMENT': 'Departaments',
    'PROFESSIONAL_CATEGORY': 'Professional Categories',
    'Nuevos': 'New',
    'Modificados': 'Updated',
    'Refrescar': 'Refresh',
    'Generar ficheros Zip': 'Generate zip files',
    'No existen sincronizaciones.': 'No sincronizations exits',
    'Copiar datos desde Sigue': 'Copy data from Sigue',
    'Esta operaciÃ³n tardarÃ¡ un poco. Ten paciencia...': 'This operation may take a while. Be patient...',
    'Volcar datos a Solicitudes': 'Dump data to Solicitudes',
    'Una vez comprobados los datos que vas a actualizar (nuevos y modificaciones), quieres volcar esta informaciÃ³n a la base de datos de Solicitudes. Esta acciÃ³n no tiene vuelta atrÃ¡s. Quieres continuar?': 'Once you have verified the data you are going to update (new and modified), you want to add this information to the \'Solicitudes\' database. This action has no turning back. Do you want to continue?',
    'Se copiaron los datos con Ã©xito': 'Data was copied successfully',
    'SincronizaciÃ³n iniciada el ': 'Synchronization started on ',
    'Ãltima sincronizaciÃ³n realizada el ': 'Last synchronization made at ',
    'Nueva sincronizaciÃ³n (volver a copiar datos desde Sigue)': 'New synchronization (copy data from Sigue again)',
    'Pendiente de volcar a solicitudes.': 'Pending to dump "Solicitudes".',
    'Volver a copiar datos desde Sigue': 'Re-copy data from "Sigue"',
    'Toda la informaciÃ³n estÃ¡ sincronizada entre Sigue y Solicitudes.': 'All information is synchronized between Sigue and Solicitudes.',
    'Generar Fichero': 'Generate file',
    'Tipo de Fichero': 'File type',
    'Ejemplo: MÃ©dico forense':'Example: Forensic Physician',
    "Universidad": "University",
    "Si no encuentras la universidad que buscas, puedes enviar un correo solicitando su creaciÃ³n a": "If you cannot find the university you are looking for, you can send an email requesting its creation to",
    "Cargo": "Position",
    "CategorÃ­a / Cargo": "Category / Position",
    "Cargo en la empresa": "Position in the company",
    "La categorÃ­a profesional del director del tÃ­tulo no permite presentar este tipo de tÃ­tulo.": "Professional category of Mster's director don't allow publish this kind of degree",
    "El tÃ­tulo no se podrÃ¡ presentar ya que la categorÃ­a profesional del director del tÃ­tulo (": "Degree will not be published as the director's professional category (",
    ") no permite crear tÃ­tulos de tipo": ") don't allow publish this kind of degrees",
    'Master': 'Permanent Academic Training Degree',
    'No se puede establecer el tipo de tÃ­tulo a partir de sus ECTS. Revisa las asignaturas para que a partir de la suma de ECTS pueda establecerse el tipo de tÃ­tulo.': 'The degree type cannot be established from its ECTS. Review the subjects so that from the sum of ECTS you can establish degree type.',
    'No se puede publicar el tÃ­tulo. ': 'Degree can be published. ',
    'No todos los directores son profesores.': 'Not all directors are degree teachers.',
    "Es modular": "Is modular",
    "Es mÃ³dulo del tÃ­tulo...": "Is module of degree...",
    "Es mÃ³dulo de ": "Is module of ",
    "Solo se muestran tÃ­tulos modulares": "Only shown modular degrees",
    "Un tÃ­tulo modular necesita al menos dos tarifas. Una \"general\" y otra para el caso de cursarlo por mÃ³dulos.": "A modular title needs at least two fees. One \"general\" and another for the case of taking it by modules.",
    "Asignaturas optativas a cursar": "Optional subjects to take",
    "ECTS por asignatura Optativa": "ECTS by each optional subject",
    "Para poder asociar al tÃ­tulo asignaturas optativas, se requiere autorizaciÃ³n. Ponte en contacto en ": "In order to associate elective subjects with the degree, authorization is required. Get in touch at ",
    "Optativa": "Optional",
    "FORMACIÃN CONTÃNUA": "CONTINUOUS LEARNING",
    "TÃ­tulos Propios y FormaciÃ³n ContÃ­nua UV": "UV-specific Degrees and Continuous Learning",
    "Crear TÃ­tulo Propio": "Create UV-specific Degree",
    "MÃ¡ster de formaciÃ³n permanente, Diploma de especializaciÃ³n, Experto Universitario": "Longlife Learning  Master, Diploma of specialisation, University Expert",
    "Microcredencial, Certificado": "Microcretencial, Certificate",
    "El tÃ­tulo no tiene ninguna asignatura.": "Degree has no subjects.",
    "Configurar asignaturas optativas": "Configure optional subjects",
    "Configurar optativas": "Configure optional subjects",
    "Al menos un codirector debe tener la categorÃ­a profesional adecuada para presentar el tÃ­tulo.": "At least one co-director must have the proper professional category",
    "Para poder marcar una asignatura como Optativa, primero configura las asignaturas optativas en la pantalla anterior.": "In order to mark a subject as optional, first configure optional subjects on the previous screen.",
    "DeclaraciÃ³n responsable de la direcciÃ³n sobre claustro": "Director's responsible statement on the teaching staff",
    "Puedes descargar la plantilla del documento \"DeclaraciÃ³n responsable de la direcciÃ³n sobre claustro\" ya generada con los datos del tÃ­tulo, pinchando en el botÃ³n \"Generar Modelo\".": "You can download the template of the document 'Director's responsible statement on the teaching staff' already generated with degree data clicking on 'Generate Model' button.",
    "Una vez generado, deberÃ¡s firmar el documento con tu firma digital y subirlo a esta plataforma.": "Once generated, you must sign the document with your digital signature and upload it to this platform.",
    "Para firmar un documento con tu firma digital, puedes utilizar varias aplicaciones:": "To sign a document with your digital signature, you can use several applications:",
    "Siguiendo estas instrucciones parar firmar.": "By following these instructions to sign.",
    "Siguiendo los pasos solicitados al arrancar la aplicaciÃ³n.": "Following the steps requested when starting the application.",
    "Convertir a formaciÃ³n contÃ­nua": "Convert to continuous learning",
    "Escribe aquÃ­ para filtrar": "Enter the search term",
    "Elige uno o varios estados": "Choose one or more statuses",
    "TÃ­tulos": "Degrees",
    "DEFAULT": "UV-specific degrees",
    "CONTINOUS_LEARNING": "Continuous Learning",
    "La suma de salarios excede el mÃ¡ximo permitido": "The sum of wages exceeds the maximum allowed",
    "Servicios E-learning Adeit": "Adeit learning services",
    "Profesores inactivos": "Disabled teachers",
    "Inactivo": "Disabled",
    "El profesor o profesora estÃ¡ inactivo o inactiva actualmente. Elige de la lista el mismo profesor o profesora, pero con la informaciÃ³n actualizada.": "Teacher is currently disabled. Choose the same teacher from the list but with its updated data",
    "El profesor o profesora estÃ¡ inactivo o inactiva": "Teacher is currently disabled",
    "Elige un profesor o una profesora de la lista": "Choose a teacher from list",
    "Elige el mismo profesor o profesora, pero con la informaciÃ³n actualizada": "Choose same teacher but with its updated associated data",
    "AlgÃºn director o directora del tÃ­tulo no se encuentra activo actualmente. Elimina al director o directora marcado como 'inactivo' y vuÃ©lvelo a aÃ±adir con sus datos actualizados. En caso de que no exista el director o directora pÃ³ngase en contacto con el soporte tÃ©cnico.": "Some degree director is currently disabled. Delete the director marked as \"inactive\" and re-add him or her with their updated data. If there is no director, contact technical support.",
    "Copiar un tÃ­tulo propio del curso acadÃ©mico anterior": "Copy an UV-specific degree from previous academic year",
    "Copiar un tÃ­tulo de formaciÃ³n contÃ­nua del curso acadÃ©mico anterior": "Copy a continuous learning degree from previous academic year",
    "Marcar tÃ­tulo como archivado": "Mark degree as archived",
    "Marcar tÃ­tulo como aprobado": "Mark degree as approved",
    "Al archivar un tÃ­tulo, este quedarÃ¡ como inactivo, no pudiendo realizar modificaciones sobre Ã©l. Quieres continuar?": "When archiving a title, it will remain inactive, and you will not be able to make changes to it. Do you want to continue?",
    "Aprobar un tÃ­tulo supone que todos los datos has sido revisados y aprobados como definitivos. No se permitirÃ¡n modificaciones posteriores. Quieres continuar?": "Approving a title means that all the data has been reviewed and approved as final. Subsequent modifications will not be allowed. Do you want to continue?",
    'Profesorado - Horas Curso AcadÃ©mico': 'Teachers - Hours By Academic Year',
    'Detalle de asignaturas(en rojo no suman al estar vinculadas a otro tÃ­tulo)': "Subject Detail(subjects in red donÂ´t accumulate since are linked with other degree)",
    "Generar todas las memorias(pdf)": "Generate all degrees reports(pdf)",
    "NÃ­vel MECES/EQF": "MECES/EQF Level",
    "Criterios de admisiÃ³n": "Admission Criteria",
    "Escribe aquÃ­ los criterios de admisiÃ³n": "Write the admission criteria here",
    "Resultados del proceso de formaciÃ³n y aprendizaje": "Results of the training and learning process",
    "Para cumplir con las exigencias del RD-L 2/2023, de 16 de marzo (DisposiciÃ³n adicional quincuagÃ©sima segunda), en el caso de los cursos que piden la realizaciÃ³n de prÃ¡cticas en empresas, se deberÃ¡ incluir el apartado correspondiente al presupuesto en la memoria de solicitudes los gastos correspondientes a la inclusiÃ³n de los estudiantes en el sistema de la Seguridad Social, siempre que la empresa en cuestiÃ³n no disponga de una bolsa de trabajo destinada a tal efecto.": "To comply with the requirements of RD-L 2/2023, of March 16\n" +
        "(fifty-second additional provision), in the case of courses\n" +
        "that ask for internships in companies, the\n" +
        "section corresponding to the budget in the request memory\n" +
        "expenses corresponding to the inclusion of students in the system\n" +
        "of Social Security, provided that the company in question does not have\n" +
        "of a job bank intended for this purpose.\n" +
        "\n"
}
