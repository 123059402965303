
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import SubjectEdit from './SubjectEdit.vue';
import SubjectTeachingGuide from './SubjectTeachingGuide.vue';
import SubjectTeachersList from './SubjectTeachersList.vue';
import {ISubject} from '../../../interfaces/subject/interfaces/models/ISubject';
import DeleteDialog from '@/components/common/DeleteDialog.vue';
import ConfigureOptionalSubject from './ConfigureOptionalSubject.vue';
// @ts-ignore
import * as _ from 'lodash';
import SubjectChildList from './SubjectChildList.vue';
import Draggable from 'vuedraggable';
import {MASTER_KIND} from '../../../helpers';

export default Vue.extend({
    name: 'SubjectList',
    props: {
        editStep: {
            type: Number,
            default: null,
        },
    },
    components: {
        SubjectEdit,
        SubjectTeachingGuide,
        SubjectTeachersList,
        DeleteDialog,
        SubjectChildList,
        Draggable,
        ConfigureOptionalSubject,
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            subjects: 'master/getSubjects',
            summaryGuide: 'master/getSummaryGuide',
            master: 'master/getData',
        }),
        isContinuousLearning() {
            return (
                this.master.master_kind_id === MASTER_KIND.CONTINUOUS_LEARNING
            );
        },
        masterHasTfm() {
            if (this.subjects == null) {
                return false;
            }

            return this.subjects.some((el: ISubject) => el.is_tfm === true);
        },
        totals() {
            return this.summaryGuide.filter((element: any) => {
                return element.title.toUpperCase() === 'TOTAL';
            })[0].years;
        },
        checkedHeaders() {
            let h = this.headers;

            if (this.master.academic_years_duration == 1) {
                h = h.filter((item) => item.value != 'academic_year_offset');
            }

            if (this.master.master_parent_id != null) {
                h = h.filter((item) => item.value != 'actions');
            }

            return h;
        },
    },
    data() {
        return {
            MASTER_KIND: MASTER_KIND,
            openTeachingGuide: false,
            openTeachers: false,
            openSubjectUI: false,
            openSubjectChildUI: false,
            deleteSubjectUI: false,
            isNewSubject: false,
            currentSubject: {},
            search: '',
            headers: [
                {
                    text: '',
                    align: 'left',
                    width: '100',
                    sortable: false,
                    value: 'actions',
                },
                {
                    text: this.$i18n.t('Orden'),
                    align: 'center',
                    width: '70',
                    sortable: true,
                    value: 'order',
                },
                {
                    text: this.$i18n.t('AÃ±o'),
                    align: 'left',
                    width: 50,
                    sortable: true,
                    value: 'academic_year_offset',
                },
                {
                    text: this.$i18n.t('Nombre'),
                    align: 'left',
                    width: 450,
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$i18n.t('T.F.M.'),
                    align: 'center',
                    width: 40,
                    sortable: false,
                    value: 'is_tfm',
                },
                {
                    text: this.$i18n.t('PrÃ¡cticas'),
                    align: 'center',
                    width: 40,
                    sortable: false,
                    value: 'is_practical',
                },
                {
                    text: this.$i18n.t('Tipo docencia'),
                    value: 'teaching_type.name',
                    align: 'center',
                    width: 70,
                    sortable: true,
                },
                {
                    text: this.$i18n.t('ECTS'),
                    value: 'ects',
                    align: 'center',
                    width: 70,
                    sortable: true,
                },
                {
                    text: this.$i18n.t('Horas asignadas'),
                    value: 'hours_assigned',
                    align: 'center',
                    width: 50,
                    sortable: true,
                },
                {
                    text: '',
                    value: 'others',
                    align: 'center',
                    width: 280,
                },
            ],
            list: [],
            openConfigureOptionalSubjectUI: false,
            editMaster: null,
        };
    },
    methods: {
        ...mapActions('master', [
            'updateSubjectsOrder',
            'deleteSubject',
            'unlinkSubject',
            'clearError',
        ]),
        getStyleOptional(item) {
            return item.is_optional ? 'color: blue;' : '';
        },
        successCreated() {
            this.openSubjectUI = false;
        },
        editSubject(subject: ISubject) {
            this.currentSubject = _.cloneDeep(subject);

            this.openSubjectUI = true;
            this.isNewSubject = false;
        },
        editTeachingGuide(item: ISubject) {
            this.currentSubject = _.cloneDeep(item);
            this.openTeachingGuide = true;
        },
        editTeachers(item: ISubject) {
            this.currentSubject = _.cloneDeep(item);
            this.openTeachers = true;
        },
        configureOptionalSubjects() {
            this.openConfigureOptionalSubjectUI = true;
        },
        newSubject() {
            this.currentSubject = {};

            this.openSubjectUI = true;
            this.isNewSubject = true;
        },
        newSubjectChild() {
            this.openSubjectChildUI = true;
        },
        cancelDeleteSubject() {
            this.currentSubject = {};

            this.deleteSubjectUI = false;
        },
        deleteSubjectDialog(subject: ISubject) {
            this.currentSubject = _.cloneDeep(subject);

            this.deleteSubjectUI = true;
        },
        async deleteCurrentSubject() {
            try {
                this.deleteSubjectUI = false;
                if (this.master.master_parent_id === null) {
                    // @ts-ignore
                    await this.deleteSubject({
                        subject: this.currentSubject,
                        master: this.master,
                    });
                } else {
                    // @ts-ignore
                    await this.unlinkSubject({
                        subject: this.currentSubject,
                        master: this.master,
                    });
                }

                this.currentSubject = {};

                this.onOrderChanged();
            } catch (err) {
                // avoid uncaught promise
            }
        },
        onOrderChanged() {
            // only update order in parent degrees
            if (this.master.master_parent_id != null) {
                return;
            }

            // save subjects order
            const listSubjects = this.$refs['orderedList']['list'];
            const orderedSubjects = listSubjects.map((subject, index) => {
                return {
                    id: subject.id,
                    order: index + 1,
                };
            });

            this.updateOrder(orderedSubjects);
        },
        async updateOrder(subjects) {
            // @ts-ignore
            await this.updateSubjectsOrder({
                subjects: subjects,
                masterId: this.master.id,
            });
        },
        getBackgroundColorByAcademicYear(academicYearOffset: number) {
            let color;

            switch (Number(academicYearOffset)) {
                case 1:
                    color = '#FFFFFF';
                    break;
                case 2:
                    color = '#FAFAFA';
                    break;
                case 3:
                    color = '#F5F5F5';
                    break;
            }

            return `background: ${color}`;
        },
        onMove() {
            // allow move only in parent degrees
            return this.master.master_parent_id == null;
        },
    },
});
