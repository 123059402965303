var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-alert',{attrs:{"outlined":"","type":"error","value":_vm.formError != null}},[_vm._v(" "+_vm._s(_vm.$t('Ha ocurrido un error en el servidor'))+" ")]),_c('v-container',{staticClass:"container fill-height fluid grid-list-xl"},[(
                _vm.isTermFinished &&
                _vm.masterState.master_state.code !== _vm.masterStates['cri'] &&
                _vm.masterState.master_state.code !== _vm.masterStates['cor']
            )?_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"flex":""}},[_c('v-card',[_c('v-card-text',{staticClass:"center-grid"},[_c('v-alert',{attrs:{"type":"warning","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t( 'El plazo de presentaciÃ³n ha finalizado.' ))+" ")])],1)],1)],1)],1):[(_vm.doNotPublishReason != null)?_c('v-layout',[_c('v-flex',{attrs:{"flex":""}},[_c('v-card',[_c('v-card-text',{staticClass:"center-grid"},[_c('v-alert',{attrs:{"type":"warning","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t(`No se puede publicar el tÃ­tulo. `))+" "+_vm._s(_vm.doNotPublishReason)+" ")])],1)],1)],1)],1):_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"flex":""}},[_c('v-card',[_c('v-card-text',{staticClass:"center-grid"},[(_vm.masterState.isValid == false)?_c('v-alert',{attrs:{"type":"warning","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t( 'No se puede presentar el tÃ­tulo. AlgÃºn paso no ha sido completado correctamente.' ))+" ")]):[(
                                        (_vm.masterState.master_state.code ==
                                            _vm.masterStates['drf'] ||
                                            _vm.masterState.master_state.code ==
                                                _vm.masterStates['cri']) &&
                                        _vm.masterState.isValid
                                    )?_c('v-btn',{staticClass:"text-none",attrs:{"x-large":""},on:{"click":function($event){return _vm.togglePublishDialog()}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("create")]),_vm._v(" "+_vm._s(_vm.$t('Presentar el tÃ­tulo'))+" ")],1):_c('v-alert',{attrs:{"type":"warning","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('El tÃ­tulo estÃ¡ '))+" "+_vm._s(_vm.$t( `${this.masterState.master_state.name}` ))+" ")])]],2)],1)],1)],1)]],2),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.publishDialog),callback:function ($$v) {_vm.publishDialog=$$v},expression:"publishDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('Presentar tÃ­tulo')))]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t( `Al presentarlo, el tÃ­tulo serÃ¡ revisado por los tÃ©cnicos y dejarÃ¡ de ser editable para el director. Quieres continuar?` ))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary_buttons darken-1"},on:{"click":function($event){_vm.togglePublishDialog();
                        _vm.publishMaster();}}},[_vm._v(_vm._s(_vm.$t('Si')))]),_c('v-btn',{attrs:{"color":"orange darken-1"},on:{"click":function($event){return _vm.togglePublishDialog()}}},[_vm._v(_vm._s(_vm.$t('Cancelar')))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }