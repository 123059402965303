
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
// @ts-ignore
import {authentication} from '@/services';
import AppEventBus from '@/AppEventBus';

export default Vue.extend({
    computed: {
        ...mapGetters({
            loginError: 'authentication/getLoginError',
        }),
    },
    props: {
        fromResetPassword: Boolean,
    },
    data: function () {
        return {
            showLoginError: false,
            valid: false,
            e1: true,
            password: '',
            passwordRules: [
                (v: any) => !!v || this.$i18n.t('ContraseÃ±a requerida'),
            ],
            email: '',
            emailRules: [
                (v: any) => !!v || this.$i18n.t('E-mail requerido'),
                (v: any) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    'E-mail must be valid',
            ],
        };
    },
    async mounted() {
        this.email = '';
        this.password = '';

        // Always clear old app_user
        authentication.logout();
    },
    methods: {
        ...mapActions('authentication', ['login', 'logout']),
        submit() {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                // @ts-ignore
                this.login({
                    login: this.email,
                    password: this.password,
                })
                    .then((loginResponse: any) => {
                        AppEventBus.$emit('login');
                        this.$router.push('/');
                    })
                    .catch((e) => {
                        this.showLoginError = true;
                    });
            }
        },
        clear() {
            // @ts-ignore
            this.$refs.form.reset();
        },
    },
});
