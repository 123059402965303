
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import * as api from '@/api/index';
import { IAcademicYear } from '../../interfaces/master/interfaces/models/IAcademicYear';
import { MASTER_KIND } from '../../helpers';
import {differenceInWeeksWithOptions} from "date-fns/fp";

export default Vue.extend({
    name: 'MasterImport',
    props: {
        masterKind: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            master: null,
            headers: [
                {
                    text: this.$i18n.t('Curso acadÃ©mico'),
                    align: 'left',
                    sortable: true,
                    value: 'academic_year',
                },
                {
                    text: this.$i18n.t('TÃ­tulo'),
                    align: 'left',
                    sortable: true,
                    value: 'title',
                },
                {
                    text: this.$i18n.t('Director'),
                    value: 'director',
                    sortable: true,
                },
                {
                    text: this.$i18n.t('ECTS'),
                    value: 'ects',
                    sortable: true,
                },
                {
                    text: this.$i18n.t('Tipo'),
                    value: 'master_type',
                    sortable: true,
                },
            ],
            masters: [],
            loading: false,
            sigueError: null,
            search: '',
            selected: [],
            importDialog: false,
            successAlert: false,
            saving: false,
            currentAcademicYear: null as unknown as IAcademicYear,
            previousAcademicYear: null as unknown as IAcademicYear,
            newMasterAcademicYear: null as unknown as IAcademicYear,
            sameNewMasterAcademicYear: false,
            pagination: {},
            totalRecords: 0,
            MASTER_KIND: MASTER_KIND,
            awaitingSearch: false,
            errors: {
                academic_year: [],
            },
            rules: {
                academic_year: [
                    (v: IAcademicYear) =>
                        !!v || this.$i18n.t('El aÃ±o acadÃ©mico es requerido'),
                ],
            },
        };
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            getOpenedAcademicYearsOwnDegrees:
                'master/getOpenedAcademicYearsOwnDegrees',
            getOpenedAcademicYearsContinuousLearningDegrees:
                'master/getOpenedAcademicYearsContinuousLearningDegrees',
        }),
        getAcademicYearsForNewOwnDegrees(): IAcademicYear[] {
            return this.getOpenedAcademicYearsOwnDegrees;
        },
        getAcademicYearsForNewContinuousLearningDegrees(): IAcademicYear[] {
            return this.getOpenedAcademicYearsContinuousLearningDegrees;
        },
        valid() {
            return this.master != null;
        },
    },
    mounted() {
        //this.getData();
        this.setAcademicYears();
        this.getLocalStorageSearch();
    },
    methods: {
      differenceInWeeksWithOptions,
        ...mapActions('master', ['clone', 'clearError']),
        getLocalStorageSearch() {
            Vue.set(
                this,
                'search',
                localStorage.getItem('search_masters_import') != 'null'
                    ? localStorage.getItem('search_masters_import')
                    : null
            );
        },
        async setAcademicYears() {
            let academicYears: IAcademicYear[];
            if (this.getOpenedAcademicYearsOwnDegrees = null || this.getOpenedAcademicYearsContinuousLearningDegrees == null) {
              return;
            }

            if (Number(this.masterKind) == MASTER_KIND.DEFAULT) {
              academicYears = this.getOpenedAcademicYearsOwnDegrees;
            } else
            {
              academicYears = this.getOpenedAcademicYearsContinuousLearningDegrees;
            }

            Vue.set(
                this,
                'currentAcademicYear',
                academicYears.sort((a: IAcademicYear, b: IAcademicYear) =>
                    b.name.localeCompare(a.name)
                )[0]
            );

            Vue.set(
                this,
                'previousAcademicYear',
                academicYears.sort((a: IAcademicYear, b: IAcademicYear) =>
                    b.name.localeCompare(a.name)
                )[1]
            );

            // By Default
            Vue.set(this, "newMasterAcademicYear", this.currentAcademicYear);

            await this.getData();
        },
        async getData() {
            try {
                if (this.laoding) {
                    return;
                }

                this.loading = true;

                const p = {
                    limit: this.pagination.itemsPerPage,
                    offset:
                        (Number(this.pagination.page) - 1) *
                        this.pagination.itemsPerPage,
                    // sortBy: this.pagination.sortBy,
                    // sortDesc: this.pagination.sortDesc
                };

                const response = await api.master.getAll({
                    pagination: p != null ? JSON.stringify(p) : null,
                    search: this.search != null ? this.search : null,
                    groupParentChilds: false,
                    onlyParents: true,
                    masterKind: this.masterKind,
                    lessThanAcademicYearId: this.currentAcademicYear.id
                });

                Vue.set(this, 'totalRecords', response.data.count);
                Vue.set(this, 'masters', response.data.data);

                this.loading = false;
            } catch (err) {
                this.loading = false;
                throw err;
            }
        },
        async save() {
            try {
                // @ts-ignore
                if (this.valid) {
                    this.saving = true;

                    // @ts-ignore
                    await this.clone({
                        masterId: this.master.id,
                        academicYearId: this.newMasterAcademicYear.id,
                    });

                    await this.getData();

                    this.saving = false;
                    this.showSuccess();
                }
            } catch (e) {
                this.saving = false;
                throw e;
            }
        },
        setSigueError(message: string | string[]) {
            // @ts-ignore
            if (message.message) {
                // @ts-ignore
                this.sigueError = message.message;
            } else {
                // @ts-ignore
                this.sigueError = message;
            }

            if (Array.isArray(message)) {
                // @ts-ignore
                this.sigueError =
                    '<span>' + message.join('</span><span>') + '</span>';
            }
        },
        rowClick: function (item, row) {
            if (item.usedForImport) {
                row.select(false);
                return;
            }

            let selectState = row.isSelected ? false : true;
            row.select(selectState);

            if (selectState) {
                this.master = item;
            } else {
                this.master = null;
            }
        },
        itemSelected: function (evt) {
            if (evt.value) {
                this.master = evt.item;
            } else {
                this.master = null;
            }
        },
        toggleImportDialog() {
            this.importDialog = !this.importDialog;
        },
        showSuccess() {
            this.successAlert = true;
            window.setInterval(() => {
                this.successAlert = false;
            }, 5000);
        },
        classForImported(usedForImport: boolean) {
            return usedForImport ? 'deleted' : '';
        },
    },
    watch: {
        getAcademicYearsForNewOwnDegrees: {
          async handler() {
            await this.setAcademicYears();
          }
        },
        getAcademicYearsForNewContinuousLearningDegrees: {
          async handler() {
            await this.setAcademicYears();
          }
        },
        pagination: {
            handler() {
                this.getData();
            },
            deep: true,
        },
        search: {
            handler: function (value, oldValue) {
                localStorage.setItem('search_masters_import', value);
                if (!this.awaitingSearch) {
                    setTimeout(() => {
                        this.pagination.page = 1;
                        this.getData();
                        this.awaitingSearch = false;
                    }, 1300); // 1 sec delay
                }
                this.awaitingSearch = true;
            },
            deep: true,
        },
        newMasterAcademicYear: {
            handler: function(value: IAcademicYear, oldValue: IAcademicYear) {
              if (value.id == this.master?.academic_year.id) {
                Vue.set(this,
                    "sameNewMasterAcademicYear",
                    true
                )
              } else
              {
                Vue.set(this,
                    "sameNewMasterAcademicYear",
                    false
                )
              }
            },
            deep: true
        }
    },
});
