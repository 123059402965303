
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';

export default Vue.extend({
    name: 'SubjectChildList',
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            master: 'master/getData'
        }),
        parentSubjects() {
            return this.master.master_parent
                ? this.master.master_parent.subjects
                : [];
        }
    },
    props: {
        currentSubjects: {
            type: Array,
            default: () => []
        },
        editStep: {
            type: Number,
            default: null
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selected: [],
            noChanges: true,
            headers: [
                {
                    text: this.$i18n.t('Nombre'),
                    align: 'left',
                    sortable: true,
                    width: 350,
                    value: 'name'
                },
                {
                    text: this.$i18n.t('Tipo docencia'),
                    value: 'teaching_type.name',
                    width: 150,
                    sortable: true
                },
                {
                    text: this.$i18n.t('ECTS'),
                    value: 'ects',
                    sortable: true
                },
                {
                    text: this.$i18n.t('T.F.M.'),
                    align: 'left',
                    sortable: true,
                    value: 'is_tfm'
                },
                {
                    text: this.$i18n.t('PrÃ¡cticas'),
                    align: 'left',
                    sortable: true,
                    value: 'is_practical'
                }
            ],
            saving: false
        };
    },
    methods: {
        ...mapActions('master', ['linkSubjects', 'clearError']),
        cancelChanges() {
            this.$emit('cancel');
        },
        async addSubjectsToMaster() {
            Vue.set(this, 'saving', true);

            await this.linkSubjects({
                subjects: this.selected,
                master: this.master
            }).then(() => Vue.set(this, 'saving', false));

            this.$emit('exit');
        },
        init() {
            Vue.set(this, 'noChanges', true);
            Vue.set(this, 'selected', this.currentSubjects);
        },
        hasChanges() {
            if (this.currentSubjects.length != this.selected.length) {
                Vue.set(this, 'noChanges', false);
                return;
            }

            const same = this.currentSubjects.filter(
                cs => !this.selected.some(se => se.id === cs.id)
            );

            if (same.length == 0) {
                Vue.set(this, 'noChanges', true);
                return;
            }

            Vue.set(this, 'noChanges', false);
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        show(newValue, oldValue) {
            if (newValue) {
                this.init();
            }
        }
    }
});
